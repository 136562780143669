export const ltLT = {
  Settings: 'Nustatymai',
  Save: 'Sutaupyti',
  'Login Page': 'Prisijungimo puslapis',
  Reset: 'Nustatyti iš naujo',
  'Main Text': 'Pagrindinis tekstas',
  'Secondary Text': 'Antrinis tekstas',
  Image: 'Vaizdas',
  'Recommended parameters': 'Rekomenduojami parametrai',
  Resolution: 'Rezoliucija',
  Proportions: 'Proporcijos',
  'Site header': 'Svetainės antraštė',
  Logo: 'Logotipas',
  'SMTP Information': 'SMTP informacija',
  Host: 'Šeimininkas',
  Port: 'Uostas',
  Login: 'Prisijungti',
  Password: 'Slaptažodis',
  'Forgot password': 'Pamiršote slaptažodį',
  'Restore password': 'Atkurti slaptažodį',
  'Reset admin password': 'Iš naujo nustatyti administratoriaus slaptažodį',
  'Back to login': 'Grįžti į prisijungimą',
  'Reset password': 'Atstatyti slaptažodį',
  Approved: 'Patvirtinta',
  Review: 'Apžvalga',
  'Not approved': 'Nepatvirtintas',
  'Updating cases, please wait': 'Atnaujinami atvejai, palaukite',
  Search: 'Paieška',
  done_at: 'eForma baigta',
  created_at: 'Duomenys pasiekiami adresu',
  worker_name: 'El. formą atliko',
  'Edit Case': 'Redaguoti atvejį',
  'Delete Case': 'Ištrinti atvejį',
  Download: 'parsisiųsti',
  'Are you sure you want to delete this case': 'Ar tikrai norite ištrinti šį atvejį',
  'Accessible at': 'Prieinama adresu',
  'Finished at': 'Baigta val',
  'Done by': 'Padaryta',
  OK: 'Gerai',
  Cancel: 'Atšaukti',
  'Edit Searchable item': 'Redaguoti ieškomą elementą',
  'Delete Searchable item': 'Ištrinti ieškomą elementą',
  'Edit searchable element': 'Redaguoti ieškomą elementą',
  Name: 'vardas',
  Ok: 'Gerai',
  'Import options (max 100 entries)': 'Importavimo parinktys (maks. 100 įrašų)',
  Import: 'Importuoti',
  'My eForms': 'Mano el. formos',
  'Create eForm': 'Sukurti el. formą',
  'New eForm': 'Nauja el. forma',
  'New eForm from XML': 'Nauja el. forma iš XML',
  Label: 'Etiketė',
  Tags: 'Žymos',
  CreatedAt: 'Sukurta adresu',
  'eForm Name': 'eFormos pavadinimas',
  Pairing: 'Poravimas',
  Actions: 'Veiksmai',
  'Edit tags': 'Redaguoti žymas',
  'Click to edit eForm pairing with user(s)': 'Spustelėkite, jei norite redaguoti el. formos susiejimą su naudotoju (-ais)',
  'Edit pairing': 'Redaguoti susiejimą',
  'Click to pair eForm with user(s)': 'Spustelėkite, kad susietumėte el. formą su naudotoju (-ais)',
  'Pair eForm': 'Susieti el. formą',
  'Show cases': 'Rodyti atvejus',
  'Edit columns': 'Redaguoti stulpelius',
  'Download CSV': 'Atsisiųskite CSV',
  'Delete eForm': 'Ištrinti el. formą',
  'Upload ZIP archive': 'Įkelti ZIP archyvą',
  'Upload template ZIP': 'Įkelti šabloną ZIP',
  'Uploading progress': 'Įkėlimo eiga',
  'Save eForm': 'Išsaugoti el. formą',
  'Device user': 'Įrenginio naudotojas',
  'Check to pair': 'Pažymėkite, kad susietumėte',
  'Save pairing': 'Išsaugoti susiejimą',
  'Select columns for displaying in Cases': 'Pasirinkite stulpelius, kurie bus rodomi skiltyje „Cases“.',
  Field: 'Laukas',
  'Are you sure you want to delete': 'Ar esate įsitikinę, kad norite ištrinti',
  'Creation Date': 'Sukūrimo data',
  'Select tags for template': 'Pasirinkite šablono žymas',
  'Template tags': 'Šablonų žymos',
  'New tag': 'Nauja žyma',
  'Tag list': 'Žymų sąrašas',
  'Add field': 'Pridėti lauką',
  'Add sub eForm': 'Pridėti papildomą el. formą',
  'Edit sub eForm': 'Redaguoti papildomą el. formą',
  'New sub eForm': 'Nauja papildoma el. forma',
  'New field': 'Naujas laukas',
  'Field is not complete': 'Laukas neužbaigtas',
  'Add new nested field': 'Pridėti lauką prie grupės',
  'Copy field': 'Kopijuoti lauką',
  'Edit field': 'Redaguoti lauką',
  'Delete field': 'Ištrinti lauką',
  'Expand field': 'Rodyti laukus grupėje',
  'Collapse field': 'Slėpti laukus grupėje',
  Mandatory: 'Privaloma',
  Type: 'Tipas',
  'Are you sure you want to delete field': 'Ar tikrai norite ištrinti lauką?',
  'eForm is not complete': 'El. forma neužpildyta',
  'Edit eForm': 'Redaguoti el. formą',
  'New nested field': 'Naujas įdėtas laukas',
  Delete: 'Ištrinti',
  'XML from eForm Builder': 'XML iš eForm Builder',
  'Paste XML here': 'Įklijuokite XML čia',
  'Creation date': 'Sukūrimo data',
  'Searchable lists': 'Ieškomi sąrašai',
  'Searchable list': 'Ieškomas sąrašas',
  'Create new searchable list': 'Sukurkite naują paieškos sąrašą',
  'Edit site': 'Redaguoti svetainę',
  'Selectable lists': 'Pasirenkami sąrašai',
  'Selectable list': 'Pasirenkamas sąrašas',
  'Create new selectable list': 'Sukurkite naują pasirenkamų sąrašą',
  'Terms of Service': 'Paslaugų teikimo sąlygos',
  'Privacy Policy': 'Privatumo politika',
  'Data Protection Terms (GDPR)': 'Duomenų apsaugos sąlygos (BDAR)',
  'Save password': 'Išsaugoti slaptažodį',
  'Edit Device User': 'Redaguoti įrenginio naudotoją',
  'First name': 'Pirmas vardas',
  'Last name': 'Pavardė',
  'Device Users': 'Įrenginio vartotojai',
  'Create Device User': 'Sukurti įrenginio vartotoją',
  'New Device User': 'Naujas įrenginio vartotojas',
  'Site ID': 'Svetainės ID',
  'Device ID': 'Įrenginio ID',
  Customer: 'Klientas',
  'Customer no & OTP': 'Kliento Nr',
  'New OTP': 'Naujas OTP',
  'Delete Device User': 'Ištrinti įrenginio vartotoją',
  WARNING: 'ĮSPĖJIMAS',
  'When pressing OK, you will deactivate the current device paired with this location': 'Paspaudę OK, išjungsite esamą įrenginį, susietą su šia vieta',
  'If there is data on the current device, this data will be lost after pressing OK': 'Jei esamame įrenginyje yra duomenų, šie duomenys bus prarasti paspaudus OK',
  Edit: 'Redaguoti',
  Sites: 'Svetainės',
  'Updated at': 'Atnaujinta adresu',
  'Are you sure you want to delete the site? The action can not be undone': 'Ar tikrai norite ištrinti svetainę? Veiksmo anuliuoti negalima',
  Previous: 'Ankstesnis',
  Next: 'Kitas',
  Units: 'Vienetai',
  'Get OTP code': 'Gaukite OTP kodą',
  'Activate OTP code for': 'Suaktyvinkite OTP kodą',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Paspaudę OK, išjungsite esamą įrenginį, susietą su šia vieta',
  'You will se the new OTP code once you click OK': 'Kai spustelėsite Gerai, pamatysite naują OTP kodą',
  Workers: 'Darbininkai',
  'Create Worker': 'Sukurti darbuotoją',
  'Edit Worker': 'Redaguoti darbuotoją',
  'Delete Worker': 'Ištrinti darbuotoją',
  'Are you sure you want to delete the worker': 'Ar tikrai norite ištrinti darbuotoją?',
  'Select Worker': 'Pasirinkite Darbuotojas',
  'Save Worker': 'Išsaugokite darbuotoją',
  Advanced: 'Admin',
  'Application Settings': 'Programos nustatymai',
  'User management': 'Vartotojo valdymas',
  'Change password': 'Pakeisti slaptažodį',
  Logout: 'Atsijungti',
  'Profile settings': 'Profilio nustatymai',
  Timezone: 'Laiko zona',
  Language: 'Kalba',
  'Force users to use 2-f-auth': 'Priverskite vartotojus naudoti 2-f-auth',
  'Old password': 'Senas slaptažodis',
  'New password': 'Naujas slaptažodis (mažiausiai 6 simboliai)',
  'New password confirmation': 'Naujo slaptažodžio patvirtinimas (mažiausiai 6 simboliai)',
  'Confirm new password': 'Patvirtinkite naują slaptažodį (mažiausiai 6 simboliai)',
  'Enter new password': 'Įveskite naują slaptažodį (mažiausiai 6 simboliai)',
  'Enter old password': 'Įveskite seną slaptažodį',
  'New User': 'Naujas vartotojas',
  'Edit User': 'Redaguoti vartotoją',
  'Left empty if want to keep old': 'Palikite tuščią, jei norite išlaikyti seną',
  Username: 'Vartotojo vardas',
  Email: 'paštas',
  Status: 'Būsena',
  'change will logout you from the application': 'pakeitus būsite atsijungę nuo programos',
  'Remove google authenticator secret key': 'Pašalinkite „Google“ autentifikavimo priemonės slaptąjį raktą',
  'Remove PSK': 'Pašalinkite PSK',
  'Full Name': 'Pilnas vardas',
  'Delete user': 'Ištrinti vartotoją',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Atidarykite „Google Authenticator“ ir pridėkite „Eform“ programą nuskaitydami QR kodą dešinėje',
  'Enter the 6 digit code that Google Authenticator generates': 'Įveskite 6 skaitmenų kodą, kurį sugeneruoja „Google“ autentifikavimo priemonė',
  General: 'Generolas',
  Find: 'Rasti',
  'Nothing found': 'Nieko nerasta',
  'Type in search box to see results': 'Norėdami pamatyti rezultatus, įveskite paieškos laukelį',
  'Select tags for filter': 'Pasirinkite filtro žymas',
  'All selected': 'Visi pasirinkti',
  'Showing to of records': 'Rodoma nuo {{currentPageAmount}} iki {{currentPage}} iš {{total}} įrašų',
  'Google Authenticator description': '<p>Slaptas raktas tarp programos <strong>Eform</strong> ir <strong>Google Authenticator</strong> . Saugokite IT ir niekam nesakyk.</p><p> Turėtumėte pašalinti šį raktą, jei <strong>„Google“ autentifikavimo priemonė</strong> įdiegta iš naujo arba raktas buvo pašalintas</p>',
  'New OTP for': 'Naujas vienkartinis slaptažodis',
  Loading: 'Įkeliama',
  Pending: 'Kol',
  Upload: 'Įkelti',
  Role: 'Vaidmuo',
  'New list': 'Naujas sąrašas',
  'Name is required': 'Vardas yra būtinas',
  'Edit searchable list': 'Redaguoti ieškomų sąrašą',
  'eForm Pairing': 'El. formų susiejimas',
  'Tag for removal': 'Pašalinimo žyma',
  'New tags': 'Naujos žymės',
  'Rotate on 90': 'Pasukti į 90',
  'Delete picture': 'Ištrinti paveikslėlį',
  'Open location': 'Atidaryti vietą',
  'File has been uploaded successfully': 'Failas sėkmingai įkeltas',
  'Error while uploading file': 'Įkeliant failą įvyko klaida',
  Customers: 'Klientai',
  'Do you want to leave this page': 'Ar norite išeiti iš šio puslapio',
  'If you leave before saving your changes will be lost': 'Jei išeisite prieš išsaugodami, pakeitimai bus prarasti',
  'Save & Leave': 'Išsaugoti ir palikti',
  Cases: 'Atvejai',
  Calendar: 'Kalendorius',
  'Case management': 'Bylų valdymas',
  Security: 'Saugumas',
  'Security Groups': 'Apsaugos grupės',
  GroupName: 'Grupės pavadinimas',
  UserAmount: 'Vartotojų kiekis',
  'Edit Group': 'Redaguoti grupę',
  Permissions: 'Leidimai',
  'Delete Group': 'Ištrinti grupę',
  'New Group': 'Nauja grupė',
  'Create Group': 'Sukurti grupę',
  Group: 'Grupė',
  'Create Security Group': 'Sukurti saugos grupę',
  'Group Name': 'Grupės pavadinimas',
  'Select User': 'Pasirinkite Vartotojas',
  'Bind to Group': 'Susieti su grupe',
  Create: 'Sukurti',
  Close: 'Uždaryti',
  'Remove user from group': 'Pašalinti vartotoją iš grupės',
  UserManagement: 'Vartotojo valdymas',
  Update: 'Atnaujinti',
  Read: 'Skaityti',
  'General Permissions': 'Bendrieji leidimai',
  Column: 'Stulpelis',
  'Eform Permissions': 'Eform leidimai',
  'Add Eform to Group': 'Pridėti Eform į grupę',
  Forbidden: 'Draudžiama',
  'Add user to group': 'Pridėti vartotoją prie grupės',
  'Update columns': 'Atnaujinti stulpelius',
  'Download XML': 'Atsisiųskite XML',
  'Upload ZIP': 'Įkelti ZIP',
  'Pairing read': 'Poravimo skaitymas',
  'Pairing update': 'Poravimo atnaujinimas',
  'Read tags': 'Skaityti žymas',
  'Update tags': 'Atnaujinkite žymas',
  'Get CSV': 'Gaukite CSV',
  'Get DOCX': 'Gaukite DOCX',
  'Get PDF': 'Gaukite PDF',
  'Get PPTX': 'Gaukite PPTX',
  'Cases read': 'Skaityti atvejai',
  'Case read': 'Perskaitytas atvejis',
  'Case update': 'Bylos atnaujinimas',
  'Case delete': 'Bylos ištrynimas',
  'Bind Eform': 'Surišti Eformą',
  'Read Jasper report': 'Perskaitykite Jasper pranešimą',
  'Update Jasper report': 'Atnaujinkite Jasper ataskaitą',
  Display: 'Ekranas',
  Enabled: 'Įjungtas',
  Disabled: 'Išjungta',
  'Plugins Settings': 'Papildiniai',
  PluginsSettings: 'Papildiniai',
  'Edit Settings': 'Redaguoti nustatymus',
  'Connection String': 'Ryšio eilutė',
  Version: 'Versija',
  'Header Picture': 'Antraštės paveikslėlis',
  Preview: 'Peržiūra',
  Commentary: 'Komentaras',
  Text: 'Tekstas',
  'Selected Value': 'Pasirinkta vertė',
  'PDF Link': 'PDF nuoroda',
  Information: 'Informacija',
  Duration: 'Trukmė',
  Audio: 'Garsas',
  'Are you sure you want to install plugin': 'Ar tikrai norite įdiegti papildinį',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Tai gali užtrukti iki 10 minučių ir perrašyti dabartinę papildinio versiją, jei ji jau yra sistemoje',
  Installation: 'Montavimas',
  Install: 'Diegti',
  Marketplace: 'Turgavietė',
  'Plugins marketplace': 'Papildinių prekyvietė',
  'Plugins Management': 'Papildinių valdymas',
  'Warning: you will be logged out when status is changed': 'Įspėjimas: būsite atjungti, kai būsena bus pakeista',
  'Select tags for site': 'Pasirinkite svetainės žymas',
  'Add new': 'Pridėti naują',
  'Email Recipients': 'El. pašto gavėjai',
  'Manage tags': 'Tvarkyti žymas',
  'Add Recipients': 'Pridėti gavėjus',
  'Edit email recipient': 'Redaguoti el. pašto gavėją',
  'Delete email recipient': 'Pašalinti el. pašto gavėją',
  'No email recipients found': 'El. pašto gavėjų nerasta',
  'New Email recipients': 'Nauji el. pašto gavėjai',
  'Email addresses': 'El. pašto adresai',
  'Edit tag name': 'Redaguoti žymos pavadinimą',
  'Tags management': 'Žymų valdymas',
  'Email/name text area should have at least one email/name pair': 'El. pašto / vardo teksto srityje turi būti bent viena el. pašto / vardo pora',
  'SendGrid Key': 'SendGrid raktas',
  'SendGrid Information': 'SendGrid informacija',
  'Saved locally': 'Išsaugota vietoje',
  'Ready for server': 'Paruošta serveriui',
  'Received by server': 'Gauta iš serverio',
  'Retrieved by device': 'Gauta pagal įrenginį',
  Completed: 'Užbaigta',
  'Case posts': 'Bylų įrašai',
  'No posts found': 'Nerasta jokių pranešimų',
  'eForm conducted by': 'El. formą atliko',
  'New post': 'Naujas įrašas',
  'Created by': 'Sukurta',
  To: 'Į',
  Subject: 'Tema',
  'Email text': 'elektroninio pašto tekstas',
  'Link to case': 'Nuoroda į bylą',
  'Attach PDF': 'Pridėti PDF',
  'Post created by': 'Įrašą sukūrė',
  'Post sent to': 'Įrašas išsiųstas į',
  Location: 'Vieta',
  'InSight Version': 'InSight versija',
  'eForm Version': 'eFormos versija',
  'Sync delay': 'Sinchronizavimo delsa',
  'Activate sync delay': 'Suaktyvinkite sinchronizavimo delsą',
  'Sync dialog': 'Sinchronizavimo dialogo langas',
  'Activate sync dialog': 'Suaktyvinti sinchronizavimo dialogo langą',
  Push: 'Stumti',
  'Activate push': 'Suaktyvinkite stūmimą',
  'Move unit': 'Perkelti įrenginį',
  'Create unit': 'Sukurti vienetą',
  'Select location': 'Pasirinkite vietą',
  Move: 'Judėti',
  'Current tags': 'Dabartinės žymos',
  'Edit selectable element': 'Redaguoti pasirenkamą elementą',
  'Edit selectable list': 'Redaguoti pasirenkamą sąrašą',
  Folders: 'Aplankai',
  Description: 'apibūdinimas',
  'Create folder': 'Sukurti aplanką',
  'Storage information': 'Saugojimo informacija',
  'Swift enabled': 'Swift įjungtas',
  'User name': 'Vartotojo vardas',
  'Swift endpoint': 'Swift galutinis taškas',
  'Keystone endpoint': 'Keystone galutinis taškas',
  'S3 enabled': 'S3 įjungtas',
  'S3 accessKey': 'S3 prieigos raktas',
  'S3 secretAccessKey': 'S3 slaptasis prieigos raktas',
  'S3 endpoint': 'S3 galutinis taškas',
  'S3 bucket name': 'S3 kibiro pavadinimas',
  'File location picture': 'Failo vietos paveikslėlis',
  'File location PDF': 'Failo vieta PDF',
  'File location Jasper report files': 'Failo vieta Jasper ataskaitos failai',
  'Http server address': 'Http serverio adresas',
  'SDK information': 'SDK informacija',
  'Customer no': 'Klientas Nr',
  Management: 'Valdymas',
  'Update security group': 'Atnaujinkite saugos grupę',
  'eForms permissions': 'eForms leidimai',
  'Menu editor': 'Meniu redaktorius',
  'Edit menu entry': 'Redaguoti meniu įrašą',
  Link: 'Nuoroda',
  'Are you sure you want to delete menu entry': 'Ar tikrai norite ištrinti meniu įrašą',
  'New dropdown': 'Naujas išskleidžiamasis meniu',
  Dropdown: 'Išskleidžiamasis meniu',
  'Security groups': 'Apsaugos grupės',
  Custom: 'Pasirinktinis',
  Add: 'Papildyti',
  'Generate report': 'Sukurti {{value}} ataskaitą',
  'Select date range': 'Pasirinkite dienų seką',
  'Edit headers': 'Redaguoti antraštes',
  'Text (TextSingleLine)': 'Tekstas viena eilutė',
  Generate: 'Sukurti ryšį',
  CheckBox: 'Žymimasis langelis',
  SingleSelect: 'Išskleidžiamasis meniu',
  Comment: 'Tekstas',
  Number: 'Skaitmeninis',
  Picture: 'Paveikslėlis',
  None: 'Informacijos dėžutė',
  Date: 'Data',
  SaveButton: 'Sutaupyti',
  ShowPDF: 'PDF',
  FieldGroup: 'Grupė',
  NumberStepper: 'Steperis',
  Signature: 'Parašas',
  Timer: 'StartStopTime',
  EntitySearch: 'Paieška išskleidžiamajame meniu (mano sąrašai)',
  EntitySelect: 'Išskleidžiamasis meniu (mano sąrašai)',
  MultiSelect: 'Keli pasirinkimai',
  'Save eForm.': 'Išsaugoti el. formą',
  'Server date': 'Serverio data',
  'eForm Pairing for': 'eForm Pairing for',
  'Select Site': 'Pasirinkite Svetainė',
  Select: 'Pasirinkite',
  Employee: 'Darbuotojas',
  Site: 'Svetainė',
  'Selected language': 'Pasirinkta kalba',
  'New folder': 'Naujas aplankas',
  Back: 'Atgal',
  Danish: 'danų',
  English: 'Anglų',
  German: 'vokiečių kalba',
  Spanish: 'ispanų',
  French: 'Prancūzų kalba',
  Italian: 'italų',
  Polish: 'lenkas',
  Portuguese: 'portugalų',
  'Brazilian Portuguese': 'Brazilijos portugalų',
  Ukrainian: 'ukrainiečių',
  Swedish: 'Švedijos',
  Norwegian: 'norvegų',
  Dutch: 'olandų',
  Finish: 'Baigti',
  'Done at': 'Priimta',
  'Select date': 'Pasirinkite datą',
  Value: 'Vertė',
  'Please fill out this field': 'Užpildykite šį lauką',
  'Submitted date': 'Pateikimo data',
  Languages: 'Kalbos',
};
