<eform-new-subheader
  [title]="'Database connection setup' | translate"
  [forceStaticTitle]="true"
>
  <button
    mat-raised-button
    [disabled]="!settingsForm.form.valid"
    color="accent"
    matTooltip="{{ 'Save settings' | translate }}"
    matTooltipPosition="left"
    id="save"
    (click)="updateConnectionString()"
  >
    {{ 'Save' | translate }}
  </button>
</eform-new-subheader>


<form #settingsForm="ngForm">
  <div class="d-flex flex-column">
    <div class="mt-2">
      <mat-card>
        <mat-card-title>
          <h4>
            {{ 'Admin information' | translate }}
          </h4>
        </mat-card-title>
        <mat-card-content class="flex-inputs-card">
          <div class="d-flex flex-column mat-input-50">
            <mat-form-field>
              <mat-label>{{'First name' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="settingsModel.adminSetupModel.firstName"
                type="text"
                name="firstName"
                #firstName="ngModel"
                id="firstName">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'Last name' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="settingsModel.adminSetupModel.lastName"
                type="text"
                name="lastName"
                #lastName="ngModel"
                id="lastName">
            </mat-form-field>
          </div>
          <div class="d-flex flex-column mat-input-50">
            <mat-form-field>
              <mat-label>{{'Email' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="settingsModel.adminSetupModel.email"
                type="email"
                name="email"
                id="email"
                #email="ngModel">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'Password' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="settingsModel.adminSetupModel.password"
                required
                type="text"
                name="password"
                #password="ngModel"
                id="password">
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="mt-4">
      <mat-card>
        <mat-card-title>
          <h4>
            {{ 'Database connection information' | translate }}
          </h4>
        </mat-card-title>
        <mat-card-content>
          <mat-form-field>
            <mat-label>{{'Token' | translate}}</mat-label>
            <input
              matInput
              [(ngModel)]="settingsModel.connectionStringSDK.token"
              placeholder="12345678909876543210adfecdfefdae"
              #tokenSdk="ngModel"
              required
              type="text"
              name="token"
              id="token">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'Customer no' | translate}}</mat-label>
            <input
              matInput
              [(ngModel)]="settingsModel.generalAppSetupSettingsModel.customerNo"
              placeholder="123"
              #customerNo="ngModel"
              required
              type="text"
              name="customerNo"
              id="customerNo">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'Server' | translate}}</mat-label>
            <input
              matInput
              [(ngModel)]="settingsModel.connectionStringSDK.host"
              placeholder="localhost"
              type="text"
              required
              name="host"
              id="host2">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'Port' | translate}}</mat-label>
            <input
              matInput
              [(ngModel)]="settingsModel.connectionStringSDK.port"
              placeholder="3306"
              type="text"
              name="port"
              id="port">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'Authentication type' | translate}}</mat-label>
            <input
              matInput
              [(ngModel)]="settingsModel.connectionStringSDK.auth"
              placeholder="user = root;password = myPassword;"
              type="text"
              name="auth"
              id="auth2">
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="mt-4">
      <mat-card>
        <mat-card-title>
          <h4>
            {{ 'Default Language' | translate }}
          </h4>
        </mat-card-title>
        <mat-card-content>
          <mat-form-field>
            <mat-label>{{'Language' | translate}}</mat-label>
            <mtx-select
              id="languageSelector"
              name="default-language"
              #defaultLanguage="ngModel"
              [bindValue]="'id'"
              [bindLabel]="'text'"
              [clearable]="false"
              [(ngModel)]="settingsModel.generalAppSetupSettingsModel.defaultLocale"
              [items]="languages"
            ></mtx-select>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
