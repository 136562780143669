export const deDE = {
  Settings: 'Einstellungen',
  Save: 'Speichern',
  'Login Page': 'Loginseite',
  Reset: 'Zurücksetzen',
  'Main Text': 'Haupt Text',
  'Secondary Text': 'Sekundärtext',
  Image: 'Bild',
  'Recommended parameters': 'Empfohlene Parameter',
  Resolution: 'Auflösung',
  Proportions: 'Proportionen',
  'Site header': 'Site-Header',
  Logo: 'Logo',
  'SMTP Information': 'SMTP-Informationen',
  Host: 'Gastgeber',
  Port: 'Hafen',
  Login: 'Anmeldung',
  Password: 'Passwort',
  'Forgot password': 'Passwort vergessen',
  'Restore password': 'Passwort wiederherstellen',
  'Reset admin password': 'Admin-Passwort zurücksetzen',
  'Back to login': 'Zurück zur Anmeldung',
  'Reset password': 'Passwort zurücksetzen',
  Approved: 'Genehmigt',
  Review: 'Rezension',
  'Not approved': 'Nicht bestätigt',
  'Updating cases, please wait': 'Fälle werden aktualisiert, bitte warten',
  Search: 'Suchen',
  done_at: 'eForm fertig um',
  created_at: 'Daten zugänglich unter',
  worker_name: 'eForm erstellt von',
  'Edit Case': 'Fall bearbeiten',
  'Delete Case': 'Fall löschen',
  Download: 'Herunterladen',
  'Are you sure you want to delete this case': 'Sind Sie sicher, dass Sie diesen Fall löschen möchten?',
  'Accessible at': 'Erreichbar unter',
  'Finished at': 'Fertig um',
  'Done by': 'Gemacht von',
  OK: 'OK',
  Cancel: 'Stornieren',
  'Edit Searchable item': 'Durchsuchbares Element bearbeiten',
  'Delete Searchable item': 'Durchsuchbares Element löschen',
  'Edit searchable element': 'Durchsuchbares Element bearbeiten',
  Name: 'Name',
  Ok: 'OK',
  'Import options (max 100 entries)': 'Importoptionen (max. 100 Einträge)',
  Import: 'Importieren',
  'My eForms': 'Meine eForms',
  'Create eForm': 'Erstellen Sie ein eForm',
  'New eForm': 'Neues eForm',
  'New eForm from XML': 'Neues eForm aus XML',
  Label: 'Etikett',
  Tags: 'Stichworte',
  CreatedAt: 'Hergestellt in',
  'eForm Name': 'eForm-Name',
  Pairing: 'Paarung',
  Actions: 'Aktionen',
  'Edit tags': 'Stichworte bearbeiten',
  'Click to edit eForm pairing with user(s)': 'Klicken Sie hier, um die eForm-Kopplung mit Benutzern zu bearbeiten.',
  'Edit pairing': 'Kopplung bearbeiten',
  'Click to pair eForm with user(s)': 'Klicken Sie hier, um eForm mit Benutzern zu koppeln.',
  'Pair eForm': 'eForm koppeln',
  'Show cases': 'Vitrinen',
  'Edit columns': 'Spalten bearbeiten',
  'Download CSV': 'CSV herunterladen',
  'Delete eForm': 'eForm löschen',
  'Upload ZIP archive': 'ZIP-Archiv hochladen',
  'Upload template ZIP': 'Laden Sie die ZIP-Vorlage hoch',
  'Uploading progress': 'Fortschritt beim Hochladen',
  'Save eForm': 'E-Formular speichern',
  'Device user': 'Gerätebenutzer',
  'Check to pair': 'Zum Koppeln markieren',
  'Save pairing': 'Kopplung speichern',
  'Select columns for displaying in Cases': 'Wählen Sie Spalten zur Anzeige in Fällen aus',
  Field: 'Feld',
  'Are you sure you want to delete': 'Sind Sie sicher, dass Sie löschen möchten',
  'Creation Date': 'Erstellungsdatum',
  'Select tags for template': 'Wählen Sie Tags für die Vorlage aus',
  'Template tags': 'Vorlagen-Tags',
  'New tag': 'Neuer Tag',
  'Tag list': 'Tag-Liste',
  'Add field': 'Feld hinzufügen',
  'Add sub eForm': 'Untergeordnetes eForm hinzufügen',
  'Edit sub eForm': 'Untergeordnetes eForm bearbeiten',
  'New sub eForm': 'Neues Unter-eForm',
  'New field': 'Neues Feld',
  'Field is not complete': 'Das Feld ist nicht vollständig',
  'Add new nested field': 'Feld zur Gruppe hinzufügen',
  'Copy field': 'Feld kopieren',
  'Edit field': 'Feld bearbeiten',
  'Delete field': 'Feld löschen',
  'Expand field': 'Felder in Gruppe anzeigen',
  'Collapse field': 'Felder in der Gruppe ausblenden',
  Mandatory: 'Obligatorisch',
  Type: 'Typ',
  'Are you sure you want to delete field': 'Sind Sie sicher, dass Sie das Feld löschen möchten?',
  'eForm is not complete': 'Das E-Formular ist nicht vollständig',
  'Edit eForm': 'eForm bearbeiten',
  'New nested field': 'Neues verschachteltes Feld',
  Delete: 'Löschen',
  'XML from eForm Builder': 'XML von eForm Builder',
  'Paste XML here': 'Fügen Sie hier XML ein',
  'Creation date': 'Erstellungsdatum',
  'Searchable lists': 'Durchsuchbare Listen',
  'Searchable list': 'Durchsuchbare Liste',
  'Create new searchable list': 'Erstellen Sie eine neue durchsuchbare Liste',
  'Edit site': 'Website bearbeiten',
  'Selectable lists': 'Auswählbare Listen',
  'Selectable list': 'Auswählbare Liste',
  'Create new selectable list': 'Neue auswählbare Liste erstellen',
  'Terms of Service': 'Nutzungsbedingungen',
  'Privacy Policy': 'Datenschutzrichtlinie',
  'Data Protection Terms (GDPR)': 'Datenschutzbestimmungen (DSGVO)',
  'Save password': 'Passwort speichern',
  'Edit Device User': 'Gerätebenutzer bearbeiten',
  'First name': 'Vorname',
  'Last name': 'Familienname, Nachname',
  'Device Users': 'Gerätebenutzer',
  'Create Device User': 'Gerätebenutzer erstellen',
  'New Device User': 'Neuer Gerätebenutzer',
  'Site ID': 'Webseitenadresse',
  'Device ID': 'Geräte ID',
  Customer: 'Kunde',
  'Customer no & OTP': 'Kundennummer und OTP',
  'New OTP': 'Neues OTP',
  'Delete Device User': 'Gerätebenutzer löschen',
  WARNING: 'WARNUNG',
  'When pressing OK, you will deactivate the current device paired with this location': 'Wenn Sie auf OK drücken, deaktivieren Sie das aktuell mit diesem Standort gekoppelte Gerät',
  'If there is data on the current device, this data will be lost after pressing OK': 'Wenn auf dem aktuellen Gerät Daten vorhanden sind, gehen diese Daten nach Drücken von OK verloren',
  Edit: 'Bearbeiten',
  Sites: 'Websites',
  'Updated at': 'Aktualisiert am',
  'Are you sure you want to delete the site? The action can not be undone': 'Sind Sie sicher, dass Sie die Website löschen möchten? Die Aktion kann nicht rückgängig gemacht werden',
  Previous: 'Vorherige',
  Next: 'Nächste',
  Units: 'Einheiten',
  'Get OTP code': 'Holen Sie sich den OTP-Code',
  'Activate OTP code for': 'OTP-Code aktivieren für',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Wenn Sie auf OK klicken, deaktivieren Sie die aktuelle Einheit, die diesem Standort zugeordnet ist',
  'You will se the new OTP code once you click OK': 'Der neue OTP-Code wird angezeigt, sobald Sie auf „OK“ klicken',
  Workers: 'Arbeitskräfte',
  'Create Worker': 'Arbeiter erstellen',
  'Edit Worker': 'Arbeiter bearbeiten',
  'Delete Worker': 'Arbeiter löschen',
  'Are you sure you want to delete the worker': 'Sind Sie sicher, dass Sie den Worker löschen möchten?',
  'Select Worker': 'Wählen Sie Arbeiter aus',
  'Save Worker': 'Arbeiter retten',
  Advanced: 'Administrator',
  'Application Settings': 'Anwendungseinstellungen',
  'User management': 'Benutzerverwaltung',
  'Change password': 'Kennwort ändern',
  Logout: 'Ausloggen',
  'Profile settings': 'Profileinstellungen',
  Timezone: 'Zeitzone',
  Language: 'Sprache',
  'Force users to use 2-f-auth': 'Erzwingen Sie, dass Benutzer 2-f-auth verwenden',
  'Old password': 'Altes Kennwort',
  'New password': 'Neues Passwort (mindestens 6 Zeichen)',
  'New password confirmation': 'Neue Passwortbestätigung (mindestens 6 Zeichen)',
  'Confirm new password': 'Neues Passwort bestätigen (mindestens 6 Zeichen)',
  'Enter new password': 'Geben Sie ein neues Passwort ein (mindestens 6 Zeichen)',
  'Enter old password': 'Geben Sie das alte Passwort ein',
  'New User': 'Neuer Benutzer',
  'Edit User': 'Benutzer bearbeiten',
  'Left empty if want to keep old': 'Leer gelassen, wenn Sie alt bleiben möchten',
  Username: 'Nutzername',
  Email: 'Email',
  Status: 'Status',
  'change will logout you from the application': 'Durch die Änderung werden Sie von der Anwendung abgemeldet',
  'Remove google authenticator secret key': 'Entfernen Sie den geheimen Schlüssel des Google-Authentifikators',
  'Remove PSK': 'PSK entfernen',
  'Full Name': 'Vollständiger Name',
  'Delete user': 'Benutzer löschen',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Öffnen Sie Google Authenticator und fügen Sie die Eform-Anwendung hinzu, indem Sie den QR-Code rechts scannen',
  'Enter the 6 digit code that Google Authenticator generates': 'Geben Sie den 6-stelligen Code ein, den Google Authenticator generiert',
  General: 'Allgemein',
  Find: 'Finden',
  'Nothing found': 'Nichts gefunden',
  'Type in search box to see results': 'Geben Sie etwas in das Suchfeld ein, um Ergebnisse anzuzeigen',
  'Select tags for filter': 'Wählen Sie Tags für den Filter aus',
  'All selected': 'Alle ausgewählt',
  'Showing to of records': 'Es werden {{currentPageAmount}} bis {{currentPage}} von {{total}} Datensätzen angezeigt',
  'Google Authenticator description': '<p>Geheimer Schlüssel zwischen <strong>Eform-</strong> Anwendung und <strong>Google Authenticator</strong> . Bewahren Sie es sicher auf und erzählen Sie es niemandem.</p><p> Sie sollten diesen Schlüssel entfernen, wenn <strong>Google Authenticator</strong> neu installiert wird oder der Schlüssel entfernt wurde</p>',
  'New OTP for': 'Neues OTP für',
  Loading: 'Wird geladen',
  Pending: 'Ausstehend',
  Upload: 'Hochladen',
  Role: 'Rolle',
  'New list': 'Neue Liste',
  'Name is required': 'Name ist erforderlich',
  'Edit searchable list': 'Durchsuchbare Liste bearbeiten',
  'eForm Pairing': 'eForm-Kopplung',
  'Tag for removal': 'Tag zum Entfernen',
  'New tags': 'Neue Tags',
  'Rotate on 90': 'Auf 90 drehen',
  'Delete picture': 'Foto löschen',
  'Open location': 'Standort öffnen',
  'File has been uploaded successfully': 'Die Datei wurde erfolgreich hochgeladen',
  'Error while uploading file': 'Fehler beim Hochladen der Datei',
  Customers: 'Kunden',
  'Do you want to leave this page': 'Möchten Sie diese Seite verlassen?',
  'If you leave before saving your changes will be lost': 'Wenn Sie die Seite vor dem Speichern verlassen, gehen Ihre Änderungen verloren',
  'Save & Leave': 'Speichern und verlassen',
  Cases: 'Fälle',
  Calendar: 'Kalender',
  'Case management': 'Fallmanagement',
  Security: 'Sicherheit',
  'Security Groups': 'Sicherheitsgruppen',
  GroupName: 'Gruppenname',
  UserAmount: 'Anzahl der Benutzer',
  'Edit Group': 'Gruppe bearbeiten',
  Permissions: 'Berechtigungen',
  'Delete Group': 'Gruppe löschen',
  'New Group': 'Neue Gruppe',
  'Create Group': 'Gruppe erstellen',
  Group: 'Gruppe',
  'Create Security Group': 'Erstellen Sie eine Sicherheitsgruppe',
  'Group Name': 'Gruppenname',
  'Select User': 'Nutzer wählen',
  'Bind to Group': 'An Gruppe binden',
  Create: 'Erstellen',
  Close: 'Schließen',
  'Remove user from group': 'Benutzer aus der Gruppe entfernen',
  UserManagement: 'Benutzerverwaltung',
  Update: 'Aktualisieren',
  Read: 'Lesen',
  'General Permissions': 'Allgemeine Berechtigungen',
  Column: 'Spalte',
  'Eform Permissions': 'Eform-Berechtigungen',
  'Add Eform to Group': 'Eform zur Gruppe hinzufügen',
  Forbidden: 'Verboten',
  'Add user to group': 'Benutzer zur Gruppe hinzufügen',
  'Update columns': 'Spalten aktualisieren',
  'Download XML': 'XML herunterladen',
  'Upload ZIP': 'ZIP hochladen',
  'Pairing read': 'Paarung lesen',
  'Pairing update': 'Pairing-Update',
  'Read tags': 'Tags lesen',
  'Update tags': 'Tags aktualisieren',
  'Get CSV': 'Holen Sie sich CSV',
  'Get DOCX': 'Holen Sie sich DOCX',
  'Get PDF': 'Holen Sie sich PDF',
  'Get PPTX': 'Holen Sie sich PPTX',
  'Cases read': 'Fälle gelesen',
  'Case read': 'Fall gelesen',
  'Case update': 'Fallaktualisierung',
  'Case delete': 'Fall löschen',
  'Bind Eform': 'Eform binden',
  'Read Jasper report': 'Lesen Sie den Jasper-Bericht',
  'Update Jasper report': 'Jasper-Bericht aktualisieren',
  Display: 'Anzeige',
  Enabled: 'Ermöglicht',
  Disabled: 'Deaktiviert',
  'Plugins Settings': 'Plugins',
  PluginsSettings: 'Plugins',
  'Edit Settings': 'Einstellungen bearbeiten',
  'Connection String': 'Verbindungszeichenfolge',
  Version: 'Ausführung',
  'Header Picture': 'Headerbild',
  Preview: 'Vorschau',
  Commentary: 'Kommentar',
  Text: 'Text',
  'Selected Value': 'Ausgewählter Wert',
  'PDF Link': 'PDF-Link',
  Information: 'Information',
  Duration: 'Dauer',
  Audio: 'Audio',
  'Are you sure you want to install plugin': 'Sind Sie sicher, dass Sie das Plugin installieren möchten?',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Dies kann bis zu 10 Minuten dauern und die aktuelle Plugin-Version neu schreiben, wenn sie bereits im System vorhanden ist',
  Installation: 'Installation',
  Install: 'Installieren',
  Marketplace: 'Marktplatz',
  'Plugins marketplace': 'Plugins-Marktplatz',
  'Plugins Management': 'Plugins-Verwaltung',
  'Warning: you will be logged out when status is changed': 'Achtung: Sie werden abgemeldet, wenn sich der Status ändert',
  'Select tags for site': 'Wählen Sie Tags für die Website aus',
  'Add new': 'Neue hinzufügen',
  'Email Recipients': 'E-Mail-Empfänger',
  'Manage tags': 'Tags verwalten',
  'Add Recipients': 'Empfänger hinzufügen',
  'Edit email recipient': 'E-Mail-Empfänger bearbeiten',
  'Delete email recipient': 'E-Mail-Empfänger löschen',
  'No email recipients found': 'Keine E-Mail-Empfänger gefunden',
  'New Email recipients': 'Neue E-Mail-Empfänger',
  'Email addresses': 'E-mailadressen',
  'Edit tag name': 'Bearbeiten Sie den Tag-Namen',
  'Tags management': 'Tag-Verwaltung',
  'Email/name text area should have at least one email/name pair': 'Der Textbereich „E-Mail/Name“ sollte mindestens ein E-Mail/Namen-Paar enthalten',
  'SendGrid Key': 'SendGrid-Schlüssel',
  'SendGrid Information': 'SendGrid-Informationen',
  'Saved locally': 'Lokal gespeichert',
  'Ready for server': 'Bereit für den Server',
  'Received by server': 'Vom Server empfangen',
  'Retrieved by device': 'Vom Gerät abgerufen',
  Completed: 'Vollendet',
  'Case posts': 'Fallbeiträge',
  'No posts found': 'Keine Einträge gefunden',
  'eForm conducted by': 'eForm durchgeführt von',
  'New post': 'Neuer Beitrag',
  'Created by': 'Erstellt von',
  To: 'Zu',
  Subject: 'Thema',
  'Email text': 'E-Mail-Text',
  'Link to case': 'Link zum Fall',
  'Attach PDF': 'PDF anhängen',
  'Post created by': 'Beitrag erstellt von',
  'Post sent to': 'Beitrag gesendet an',
  Location: 'Standort',
  'InSight Version': 'InSight-Version',
  'eForm Version': 'eForm-Version',
  'Sync delay': 'Synchronisierungsverzögerung',
  'Activate sync delay': 'Synchronisierungsverzögerung aktivieren',
  'Sync dialog': 'Dialogfeld „Synchronisieren“.',
  'Activate sync dialog': 'Synchronisierungsdialog aktivieren',
  Push: 'Drücken',
  'Activate push': 'Push aktivieren',
  'Move unit': 'Einheit bewegen',
  'Create unit': 'Einheit erstellen',
  'Select location': 'Ort auswählen',
  Move: 'Bewegen',
  'Current tags': 'Aktuelle Tags',
  'Edit selectable element': 'Auswählbares Element bearbeiten',
  'Edit selectable list': 'Auswählbare Liste bearbeiten',
  Folders: 'Ordner',
  Description: 'Beschreibung',
  'Create folder': 'Ordner erstellen',
  'Storage information': 'Speicherinformationen',
  'Swift enabled': 'Swift aktiviert',
  'User name': 'Nutzername',
  'Swift endpoint': 'Schneller Endpunkt',
  'Keystone endpoint': 'Keystone-Endpunkt',
  'S3 enabled': 'S3 aktiviert',
  'S3 accessKey': 'S3-Zugriffsschlüssel',
  'S3 secretAccessKey': 'S3 SecretAccessKey',
  'S3 endpoint': 'S3-Endpunkt',
  'S3 bucket name': 'Name des S3-Buckets',
  'File location picture': 'Bild des Dateispeicherorts',
  'File location PDF': 'Dateispeicherort PDF',
  'File location Jasper report files': 'Dateispeicherort Jasper-Berichtsdateien',
  'Http server address': 'HTTP-Serveradresse',
  'SDK information': 'SDK-Informationen',
  'Customer no': 'Kunden-Nr',
  Management: 'Management',
  'Update security group': 'Sicherheitsgruppe aktualisieren',
  'eForms permissions': 'eForms-Berechtigungen',
  'Menu editor': 'Menüeditor',
  'Edit menu entry': 'Menüeintrag bearbeiten',
  Link: 'Verknüpfung',
  'Are you sure you want to delete menu entry': 'Sind Sie sicher, dass Sie den Menüeintrag löschen möchten?',
  'New dropdown': 'Neues Dropdown',
  Dropdown: 'Runterfallen',
  'Security groups': 'Sicherheitsgruppen',
  Custom: 'Brauch',
  Add: 'Hinzufügen',
  'Generate report': '{{value}}-Bericht erstellen',
  'Select date range': 'Wählen Sie den Datumsbereich aus',
  'Edit headers': 'Überschriften bearbeiten',
  'Text (TextSingleLine)': 'Text einzeilig',
  Generate: 'Schaffen Sie eine Beziehung',
  CheckBox: 'Kontrollkästchen',
  SingleSelect: 'Runterfallen',
  Comment: 'Text',
  Number: 'Numerisch',
  Picture: 'Bild',
  None: 'Infobox',
  Date: 'Datum',
  SaveButton: 'Speichern',
  ShowPDF: 'PDF',
  FieldGroup: 'Gruppe',
  NumberStepper: 'Stepper',
  Signature: 'Unterschrift',
  Timer: 'StartStopTime',
  EntitySearch: 'Dropdown-Suche (meine Listen)',
  EntitySelect: 'Dropdown (meine Listen)',
  MultiSelect: 'Mehrfachauswahl',
  'Save eForm.': 'E-Formular speichern',
  'Server date': 'Serverdatum',
  'eForm Pairing for': 'eForm-Pairing für',
  'Select Site': 'Wählen Sie Site aus',
  Select: 'Wählen',
  Employee: 'Mitarbeiter',
  Site: 'Website',
  'Selected language': 'Ausgewählte Sprache',
  'New folder': 'Neuer Ordner',
  Back: 'Zurück',
  Danish: 'dänisch',
  English: 'Englisch',
  German: 'Deutsch',
  Spanish: 'Spanisch',
  French: 'Französisch',
  Italian: 'Italienisch',
  Polish: 'Polieren',
  Portuguese: 'Portugiesisch',
  'Brazilian Portuguese': 'Brasilianisches Portugiesisch',
  Ukrainian: 'ukrainisch',
  Swedish: 'Schwedisch',
  Norwegian: 'norwegisch',
  Dutch: 'Niederländisch',
  Finish: 'Beenden',
  'Done at': 'Fertig am',
  'Select date': 'Datum auswählen',
  Value: 'Wert',
  'Please fill out this field': 'Bitte füllen Sie dieses Feld aus',
  'Submitted date': 'Datum der Einreichung',
  Languages: 'Sprachen',
};
