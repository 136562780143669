export const bgBG = {
  Settings: 'Настройки',
  Save: 'Запазване',
  'Login Page': 'Страница за вход',
  Reset: 'Нулиране',
  'Main Text': 'Основен текст',
  'Secondary Text': 'Вторичен текст',
  Image: 'Изображение',
  'Recommended parameters': 'Препоръчителни параметри',
  Resolution: 'Резолюция',
  Proportions: 'Пропорции',
  'Site header': 'Хедър на сайта',
  Logo: 'Лого',
  'SMTP Information': 'Информация за SMTP',
  Host: 'Домакин',
  Port: 'Порт',
  Login: 'Влизам',
  Password: 'Парола',
  'Forgot password': 'Забравена парола',
  'Restore password': 'Възстановете паролата',
  'Reset admin password': 'Нулирайте администраторската парола',
  'Back to login': 'Назад към входа',
  'Reset password': 'Нулирайте паролата',
  Approved: 'Одобрено',
  Review: 'Преглед',
  'Not approved': 'Неодобрен',
  'Updating cases, please wait': 'Случаите се актуализират, моля, изчакайте',
  Search: 'Търсене',
  done_at: 'eForm завърши в',
  created_at: 'Данните са достъпни на',
  worker_name: 'eForm, изготвен от',
  'Edit Case': 'Редактиране на случай',
  'Delete Case': 'Изтриване на случай',
  Download: 'Изтегли',
  'Are you sure you want to delete this case': 'Сигурни ли сте, че искате да изтриете този случай',
  'Accessible at': 'Достъпен на',
  'Finished at': 'Завършен на',
  'Done by': 'Направено от',
  OK: 'Добре',
  Cancel: 'Отказ',
  'Edit Searchable item': 'Редактиране на търсения елемент',
  'Delete Searchable item': 'Изтриване на търсения елемент',
  'Edit searchable element': 'Редактиране на елемент с възможност за търсене',
  Name: 'Име',
  Ok: 'Добре',
  'Import options (max 100 entries)': 'Опции за импортиране (максимум 100 записа)',
  Import: 'Импортиране',
  'My eForms': 'Моите електронни формуляри',
  'Create eForm': 'Създайте eForm',
  'New eForm': 'Нов електронен формуляр',
  'New eForm from XML': 'Нов eForm от XML',
  Label: 'Етикет',
  Tags: 'Етикети',
  CreatedAt: 'Създаден на',
  'eForm Name': 'Име на eForm',
  Pairing: 'Сдвояване',
  Actions: 'Действия',
  'Edit tags': 'Редактиране на етикети',
  'Click to edit eForm pairing with user(s)': 'Щракнете, за да редактирате сдвояването на eForm с потребител(и)',
  'Edit pairing': 'Редактиране на сдвояването',
  'Click to pair eForm with user(s)': 'Щракнете, за да сдвоите eForm с потребител(и)',
  'Pair eForm': 'Сдвояване на eForm',
  'Show cases': 'Показване на случаи',
  'Edit columns': 'Редактиране на колони',
  'Download CSV': 'Изтеглете CSV',
  'Delete eForm': 'Изтриване на eForm',
  'Upload ZIP archive': 'Качете ZIP архив',
  'Upload template ZIP': 'Качване на шаблон ZIP',
  'Uploading progress': 'Напредък на качването',
  'Save eForm': 'Запазете eForm',
  'Device user': 'Потребител на устройството',
  'Check to pair': 'Поставете отметка за сдвояване',
  'Save pairing': 'Запазване на сдвояването',
  'Select columns for displaying in Cases': 'Изберете колони за показване в Cases',
  Field: 'Поле',
  'Are you sure you want to delete': 'Сигурен ли си, че искаш да изтриеш',
  'Creation Date': 'Дата на създаване',
  'Select tags for template': 'Изберете тагове за шаблон',
  'Template tags': 'Шаблонни етикети',
  'New tag': 'Нов етикет',
  'Tag list': 'Списък с етикети',
  'Add field': 'Добавяне на поле',
  'Add sub eForm': 'Добавете поделектронен формуляр',
  'Edit sub eForm': 'Редактиране на подформуляр',
  'New sub eForm': 'Нов поделектронен формуляр',
  'New field': 'Ново поле',
  'Field is not complete': 'Полето не е пълно',
  'Add new nested field': 'Добавете поле към групата',
  'Copy field': 'Поле за копиране',
  'Edit field': 'Поле за редактиране',
  'Delete field': 'Изтриване на поле',
  'Expand field': 'Показване на полетата в групата',
  'Collapse field': 'Скриване на полетата в групата',
  Mandatory: 'Задължителен',
  Type: 'Тип',
  'Are you sure you want to delete field': 'Сигурни ли сте, че искате да изтриете полето',
  'eForm is not complete': 'Електронният формуляр не е пълен',
  'Edit eForm': 'Редактирайте електронния формуляр',
  'New nested field': 'Ново вложено поле',
  Delete: 'Изтрий',
  'XML from eForm Builder': 'XML от eForm Builder',
  'Paste XML here': 'Поставете XML тук',
  'Creation date': 'Дата на създаване',
  'Searchable lists': 'Списъци с възможност за търсене',
  'Searchable list': 'Списък с възможност за търсене',
  'Create new searchable list': 'Създайте нов списък с възможност за търсене',
  'Edit site': 'Редактиране на сайта',
  'Selectable lists': 'Списъци с възможност за избор',
  'Selectable list': 'Списък с възможност за избор',
  'Create new selectable list': 'Създайте нов избираем списък',
  'Terms of Service': 'Условия за ползване',
  'Privacy Policy': 'Политика за поверителност',
  'Data Protection Terms (GDPR)': 'Условия за защита на данните (GDPR)',
  'Save password': 'Запази паролата',
  'Edit Device User': 'Редактиране на потребителя на устройството',
  'First name': 'Първо име',
  'Last name': 'Фамилия',
  'Device Users': 'Потребители на устройството',
  'Create Device User': 'Създайте потребител на устройството',
  'New Device User': 'Нов потребител на устройството',
  'Site ID': 'ID на сайта',
  'Device ID': 'ID на устройството',
  Customer: 'Клиент',
  'Customer no & OTP': 'Номер на клиент и OTP',
  'New OTP': 'Нов OTP',
  'Delete Device User': 'Изтриване на потребител на устройството',
  WARNING: 'ВНИМАНИЕ',
  'When pressing OK, you will deactivate the current device paired with this location': 'Когато натиснете OK, ще деактивирате текущото устройство, сдвоено с това местоположение',
  'If there is data on the current device, this data will be lost after pressing OK': 'Ако има данни на текущото устройство, тези данни ще бъдат загубени след натискане на OK',
  Edit: 'редактиране',
  Sites: 'сайтове',
  'Updated at': 'Актуализиран на',
  'Are you sure you want to delete the site? The action can not be undone': 'Сигурни ли сте, че искате да изтриете сайта? Действието не може да бъде отменено',
  Previous: 'Предишен',
  Next: 'Следващия',
  Units: 'Единици',
  'Get OTP code': 'Вземете OTP код',
  'Activate OTP code for': 'Активирайте OTP код за',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'При натискане на OK ще деактивирате текущата единица, свързана с това местоположение',
  'You will se the new OTP code once you click OK': 'Ще видите новия OTP код, след като щракнете върху OK',
  Workers: 'работници',
  'Create Worker': 'Създаване на работник',
  'Edit Worker': 'Редактиране на работник',
  'Delete Worker': 'Изтриване на работник',
  'Are you sure you want to delete the worker': 'Сигурни ли сте, че искате да изтриете работника',
  'Select Worker': 'Изберете Работник',
  'Save Worker': 'Save Worker',
  Advanced: 'Админ',
  'Application Settings': 'Настройки на приложението',
  'User management': 'Управление на потребители',
  'Change password': 'Промяна на паролата',
  Logout: 'Излез от профила си',
  'Profile settings': 'Настройки на профила',
  Timezone: 'Часова зона',
  Language: 'език',
  'Force users to use 2-f-auth': 'Принуждавайте потребителите да използват 2-f-auth',
  'Old password': 'Стара парола',
  'New password': 'Нова парола (мин. 6 знака)',
  'New password confirmation': 'Потвърждение на нова парола (мин. 6 знака)',
  'Confirm new password': 'Потвърдете новата парола (мин. 6 знака)',
  'Enter new password': 'Въведете нова парола (мин. 6 знака)',
  'Enter old password': 'Въведете старата парола',
  'New User': 'Нов потребител',
  'Edit User': 'Редактиране на потребител',
  'Left empty if want to keep old': 'Оставете празно, ако искате да запазите старото',
  Username: 'Потребителско име',
  Email: 'Електронна поща',
  Status: 'Статус',
  'change will logout you from the application': 'промяната ще ви излезе от приложението',
  'Remove google authenticator secret key': 'Премахнете секретния ключ на google authenticator',
  'Remove PSK': 'Премахнете PSK',
  'Full Name': 'Пълно име',
  'Delete user': 'Изтриване на потребител',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Отворете Google Authenticator и добавете приложението Eform, като сканирате QR кода вдясно',
  'Enter the 6 digit code that Google Authenticator generates': 'Въведете 6-цифрения код, който Google Authenticator генерира',
  General: 'Общ',
  Find: 'намирам',
  'Nothing found': 'Нищо не е намерено',
  'Type in search box to see results': 'Въведете в полето за търсене, за да видите резултатите',
  'Select tags for filter': 'Изберете тагове за филтър',
  'All selected': 'Всички избрани',
  'Showing to of records': 'Показани са {{currentPageAmount}} до {{currentPage}} от {{total}} записа',
  'Google Authenticator description': '<p>Таен ключ между приложението <strong>Eform</strong> и <strong>Google Authenticator</strong> . Пазете го на сигурно място и не казвайте на никого.</p><p> Трябва да премахнете този ключ, ако <strong>Google Authenticator</strong> бъде преинсталиран или ключът е премахнат</p>',
  'New OTP for': 'Ново OTP за',
  Loading: 'Зареждане',
  Pending: 'В очакване',
  Upload: 'Качване',
  Role: 'Роля',
  'New list': 'Нов списък',
  'Name is required': 'Името е задължително',
  'Edit searchable list': 'Редактиране на списък с възможности за търсене',
  'eForm Pairing': 'Сдвояване на eForm',
  'Tag for removal': 'Етикет за премахване',
  'New tags': 'Нови етикети',
  'Rotate on 90': 'Завъртете на 90',
  'Delete picture': 'Изтриване на снимка',
  'Open location': 'Отворено местоположение',
  'File has been uploaded successfully': 'Файлът е качен успешно',
  'Error while uploading file': 'Грешка при качване на файл',
  Customers: 'Клиенти',
  'Do you want to leave this page': 'Искате ли да напуснете тази страница',
  'If you leave before saving your changes will be lost': 'Ако напуснете преди да запазите промените ви ще бъдат загубени',
  'Save & Leave': 'Запази и напусни',
  Cases: 'Случаи',
  Calendar: 'Календар',
  'Case management': 'Управление на случаи',
  Security: 'Сигурност',
  'Security Groups': 'Групи за сигурност',
  GroupName: 'Име на групата',
  UserAmount: 'Количество на потребителите',
  'Edit Group': 'Редактиране на група',
  Permissions: 'Разрешения',
  'Delete Group': 'Изтриване на група',
  'New Group': 'Нова група',
  'Create Group': 'Създай група',
  Group: 'Група',
  'Create Security Group': 'Създайте група за сигурност',
  'Group Name': 'Име на групата',
  'Select User': 'Изберете Потребител',
  'Bind to Group': 'Свързване към група',
  Create: 'Създавайте',
  Close: 'Близо',
  'Remove user from group': 'Премахване на потребителя от групата',
  UserManagement: 'Управление на потребители',
  Update: 'Актуализация',
  Read: 'Прочети',
  'General Permissions': 'Общи разрешения',
  Column: 'Колона',
  'Eform Permissions': 'Eform разрешения',
  'Add Eform to Group': 'Добавете Eform към групата',
  Forbidden: 'Забранен',
  'Add user to group': 'Добавете потребител към групата',
  'Update columns': 'Актуализиране на колони',
  'Download XML': 'Изтеглете XML',
  'Upload ZIP': 'Качване на ZIP',
  'Pairing read': 'Прочетено сдвояване',
  'Pairing update': 'Актуализация на сдвояването',
  'Read tags': 'Прочетете етикети',
  'Update tags': 'Актуализиране на етикети',
  'Get CSV': 'Вземете CSV',
  'Get DOCX': 'Вземете DOCX',
  'Get PDF': 'Вземете PDF',
  'Get PPTX': 'Вземете PPTX',
  'Cases read': 'Прочетени случаи',
  'Case read': 'Прочетено дело',
  'Case update': 'Актуализация на случая',
  'Case delete': 'Изтриване на случай',
  'Bind Eform': 'Свържете Eform',
  'Read Jasper report': 'Прочетете доклада на Джаспър',
  'Update Jasper report': 'Актуализирайте отчета на Jasper',
  Display: 'Дисплей',
  Enabled: 'Активирано',
  Disabled: 'хора с увреждания',
  'Plugins Settings': 'Плъгини',
  PluginsSettings: 'Плъгини',
  'Edit Settings': 'Редактиране на настройките',
  'Connection String': 'Низ за свързване',
  Version: 'Версия',
  'Header Picture': 'Заглавна снимка',
  Preview: 'Преглед',
  Commentary: 'Коментар',
  Text: 'Текст',
  'Selected Value': 'Избрана стойност',
  'PDF Link': 'PDF връзка',
  Information: 'Информация',
  Duration: 'Продължителност',
  Audio: 'аудио',
  'Are you sure you want to install plugin': 'Сигурни ли сте, че искате да инсталирате плъгин',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Това може да отнеме до 10 минути и да пренапише текущата версия на приставката, ако вече съществува в системата',
  Installation: 'Инсталация',
  Install: 'Инсталирай',
  Marketplace: 'Пазар',
  'Plugins marketplace': 'Пазар на плъгини',
  'Plugins Management': 'Управление на плъгини',
  'Warning: you will be logged out when status is changed': 'Предупреждение: ще излезете от системата, когато статусът се промени',
  'Select tags for site': 'Изберете тагове за сайта',
  'Add new': 'Добави нов',
  'Email Recipients': 'Получатели на имейл',
  'Manage tags': 'Управление на етикети',
  'Add Recipients': 'Добавяне на получатели',
  'Edit email recipient': 'Редактиране на получателя на имейл',
  'Delete email recipient': 'Изтриване на имейл получателя',
  'No email recipients found': 'Няма намерени получатели на имейл',
  'New Email recipients': 'Нови получатели на имейл',
  'Email addresses': 'Имейл адреси',
  'Edit tag name': 'Редактирайте името на етикета',
  'Tags management': 'Управление на етикети',
  'Email/name text area should have at least one email/name pair': 'Текстовата област за имейл/име трябва да има поне една двойка имейл/име',
  'SendGrid Key': 'Ключ SendGrid',
  'SendGrid Information': 'Информация за SendGrid',
  'Saved locally': 'Запазени локално',
  'Ready for server': 'Готов за сървър',
  'Received by server': 'Получено от сървъра',
  'Retrieved by device': 'Извлечено от устройство',
  Completed: 'Завършено',
  'Case posts': 'Случайни постове',
  'No posts found': 'Няма намерени публикации',
  'eForm conducted by': 'eForm, проведен от',
  'New post': 'Нова публикация',
  'Created by': 'Създадено от',
  To: 'Да се',
  Subject: 'Предмет',
  'Email text': 'текст на имейла',
  'Link to case': 'Линк към случая',
  'Attach PDF': 'Прикачете PDF',
  'Post created by': 'Публикация, създадена от',
  'Post sent to': 'Публикацията е изпратена до',
  Location: 'Местоположение',
  'InSight Version': 'Версия на InSight',
  'eForm Version': 'Версия на eForm',
  'Sync delay': 'Закъснение при синхронизиране',
  'Activate sync delay': 'Активиране на забавяне на синхронизирането',
  'Sync dialog': 'Диалогов прозорец за синхронизиране',
  'Activate sync dialog': 'Активиране на диалоговия прозорец за синхронизиране',
  Push: 'Бутане',
  'Activate push': 'Активирайте натискане',
  'Move unit': 'Преместете единица',
  'Create unit': 'Създайте единица',
  'Select location': 'Изберете местоположение',
  Move: 'Ход',
  'Current tags': 'Актуални тагове',
  'Edit selectable element': 'Редактиране на избираем елемент',
  'Edit selectable list': 'Редактиране на избираем списък',
  Folders: 'Папки',
  Description: 'Описание',
  'Create folder': 'Създай папка',
  'Storage information': 'Информация за съхранение',
  'Swift enabled': 'Swift е активиран',
  'User name': 'Потребителско име',
  'Swift endpoint': 'Бърза крайна точка',
  'Keystone endpoint': 'Крайна точка на Keystone',
  'S3 enabled': 'S3 активиран',
  'S3 accessKey': 'S3 ключ за достъп',
  'S3 secretAccessKey': 'S3 secretAccessKey',
  'S3 endpoint': 'S3 крайна точка',
  'S3 bucket name': 'Име на кофа S3',
  'File location picture': 'Картина на местоположението на файла',
  'File location PDF': 'Местоположение на файла PDF',
  'File location Jasper report files': 'Местоположение на файла Jasper отчетни файлове',
  'Http server address': 'Адрес на Http сървър',
  'SDK information': 'Информация за SDK',
  'Customer no': 'Клиентски номер',
  Management: 'Управление',
  'Update security group': 'Актуализирайте групата за сигурност',
  'eForms permissions': 'разрешения за eForms',
  'Menu editor': 'Редактор на менюто',
  'Edit menu entry': 'Редактиране на запис в менюто',
  Link: 'Връзка',
  'Are you sure you want to delete menu entry': 'Сигурни ли сте, че искате да изтриете запис от менюто',
  'New dropdown': 'Ново падащо меню',
  Dropdown: 'Падащо меню',
  'Security groups': 'Групи за сигурност',
  Custom: 'Персонализиран',
  Add: 'Добавете',
  'Generate report': 'Генерирайте {{value}} отчет',
  'Select date range': 'Изберете период от време',
  'Edit headers': 'Редактиране на заглавки',
  'Text (TextSingleLine)': 'Текст на един ред',
  Generate: 'Генерирайте разбирателство',
  CheckBox: 'Квадратче за отметка',
  SingleSelect: 'Падащо меню',
  Comment: 'Текст',
  Number: 'Числен',
  Picture: 'Снимка',
  None: 'Инфокутия',
  Date: 'Дата',
  SaveButton: 'Запазване',
  ShowPDF: 'PDF',
  FieldGroup: 'Група',
  NumberStepper: 'Степер',
  Signature: 'Подпис',
  Timer: 'StartStopTime',
  EntitySearch: 'Търсене в падащо меню (моите списъци)',
  EntitySelect: 'Падащо меню (моите списъци)',
  MultiSelect: 'Множествен избор',
  'Save eForm.': 'Запазете eForm',
  'Server date': 'Дата на сървъра',
  'eForm Pairing for': 'eForm Сдвояване за',
  'Select Site': 'Изберете Сайт',
  Select: 'Изберете',
  Employee: 'служител',
  Site: 'сайт',
  'Selected language': 'Избран език',
  'New folder': 'Нова папка',
  Back: 'обратно',
  Danish: 'датски',
  English: 'Английски',
  German: 'Немски',
  Spanish: 'испански',
  French: 'Френски',
  Italian: 'Италиански',
  Polish: 'полски',
  Portuguese: 'португалски',
  'Brazilian Portuguese': 'бразилски португалски',
  Ukrainian: 'украински',
  Swedish: 'шведски',
  Norwegian: 'норвежки',
  Dutch: 'холандски',
  Finish: 'завършек',
  'Done at': 'Съставено в',
  'Select date': 'Изберете дата',
  Value: 'Стойност',
  'Please fill out this field': 'Моля, попълнете това поле',
  'Submitted date': 'Дата на подаване',
  Languages: 'Езици',
};
