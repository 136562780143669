export const hrHR = {
  Settings: 'postavke',
  Save: 'Uštedjeti',
  'Login Page': 'Stranica za prijavu',
  Reset: 'Resetiraj',
  'Main Text': 'Glavni tekst',
  'Secondary Text': 'Sekundarni tekst',
  Image: 'Slika',
  'Recommended parameters': 'Preporučeni parametri',
  Resolution: 'Rezolucija',
  Proportions: 'Proporcije',
  'Site header': 'Zaglavlje stranice',
  Logo: 'Logo',
  'SMTP Information': 'SMTP informacije',
  Host: 'Domaćin',
  Port: 'Luka',
  Login: 'Prijaviti se',
  Password: 'Lozinka',
  'Forgot password': 'Zaboravili ste lozinku',
  'Restore password': 'Vrati lozinku',
  'Reset admin password': 'Poništi administratorsku lozinku',
  'Back to login': 'Natrag na prijavu',
  'Reset password': 'Resetiranje lozinke',
  Approved: 'Odobreno',
  Review: 'Pregled',
  'Not approved': 'Nije odobreno',
  'Updating cases, please wait': 'Ažuriranje slučajeva, molimo pričekajte',
  Search: 'traži',
  done_at: 'eForm je završio u',
  created_at: 'Podaci dostupni na',
  worker_name: 'eForm izradio',
  'Edit Case': 'Uredi slučaj',
  'Delete Case': 'Izbriši slučaj',
  Download: 'preuzimanje datoteka',
  'Are you sure you want to delete this case': 'Jeste li sigurni da želite izbrisati ovaj slučaj?',
  'Accessible at': 'Dostupno na',
  'Finished at': 'Završeno u',
  'Done by': 'Učinio',
  OK: 'u redu',
  Cancel: 'Otkazati',
  'Edit Searchable item': 'Uredi pretraživu stavku',
  'Delete Searchable item': 'Izbriši pretraživu stavku',
  'Edit searchable element': 'Uredi pretraživi element',
  Name: 'Ime',
  Ok: 'U redu',
  'Import options (max 100 entries)': 'Opcije uvoza (najviše 100 unosa)',
  Import: 'Uvoz',
  'My eForms': 'Moji e-obrasci',
  'Create eForm': 'Napravite eForm',
  'New eForm': 'Novi eObrazac',
  'New eForm from XML': 'Novi eForm iz XML-a',
  Label: 'Označiti',
  Tags: 'Oznake',
  CreatedAt: 'Stvoreno u',
  'eForm Name': 'Ime eObrasca',
  Pairing: 'Uparivanje',
  Actions: 'Radnje',
  'Edit tags': 'Uredite oznake',
  'Click to edit eForm pairing with user(s)': 'Kliknite za uređivanje eForm uparivanja s korisnikom(ima)',
  'Edit pairing': 'Uredi uparivanje',
  'Click to pair eForm with user(s)': 'Kliknite za uparivanje eForma s korisnikom(ima)',
  'Pair eForm': 'Uparite eForm',
  'Show cases': 'Show case',
  'Edit columns': 'Uredite stupce',
  'Download CSV': 'Preuzmite CSV',
  'Delete eForm': 'Izbriši eForm',
  'Upload ZIP archive': 'Prenesi ZIP arhivu',
  'Upload template ZIP': 'Učitajte ZIP predložak',
  'Uploading progress': 'Napredak učitavanja',
  'Save eForm': 'Spremi eForm',
  'Device user': 'Korisnik uređaja',
  'Check to pair': 'Provjerite za uparivanje',
  'Save pairing': 'Spremi uparivanje',
  'Select columns for displaying in Cases': 'Odaberite stupce za prikaz u predmetima',
  Field: 'Polje',
  'Are you sure you want to delete': 'Jeste li sigurni da želite izbrisati',
  'Creation Date': 'Datum stvaranja',
  'Select tags for template': 'Odaberite oznake za predložak',
  'Template tags': 'Oznake predložaka',
  'New tag': 'Nova oznaka',
  'Tag list': 'Popis oznaka',
  'Add field': 'Dodaj polje',
  'Add sub eForm': 'Dodajte podeObrazac',
  'Edit sub eForm': 'Uredi podeObrazac',
  'New sub eForm': 'Novi podeObrazac',
  'New field': 'Novo polje',
  'Field is not complete': 'Polje nije potpuno',
  'Add new nested field': 'Dodajte polje grupi',
  'Copy field': 'Kopiraj polje',
  'Edit field': 'Polje za uređivanje',
  'Delete field': 'Brisanje polja',
  'Expand field': 'Prikaži polja u grupi',
  'Collapse field': 'Sakrij polja u grupi',
  Mandatory: 'Obavezno',
  Type: 'Tip',
  'Are you sure you want to delete field': 'Jeste li sigurni da želite izbrisati polje',
  'eForm is not complete': 'eObrazac nije potpun',
  'Edit eForm': 'Uredi eObrazac',
  'New nested field': 'Novo ugniježđeno polje',
  Delete: 'Izbrisati',
  'XML from eForm Builder': 'XML iz eForm Buildera',
  'Paste XML here': 'Ovdje zalijepite XML',
  'Creation date': 'Datum stvaranja',
  'Searchable lists': 'Pretraživi popisi',
  'Searchable list': 'Pretraživi popis',
  'Create new searchable list': 'Stvorite novi popis koji se može pretraživati',
  'Edit site': 'Uredi mjesto',
  'Selectable lists': 'Popisi koji se mogu odabrati',
  'Selectable list': 'Popis koji se može odabrati',
  'Create new selectable list': 'Stvorite novi popis koji se može odabrati',
  'Terms of Service': 'Uvjeti pružanja usluge',
  'Privacy Policy': 'Politika privatnosti',
  'Data Protection Terms (GDPR)': 'Uvjeti zaštite podataka (GDPR)',
  'Save password': 'Spremi lozinku',
  'Edit Device User': 'Uredi korisnika uređaja',
  'First name': 'Ime',
  'Last name': 'Prezime',
  'Device Users': 'Korisnici uređaja',
  'Create Device User': 'Stvori korisnika uređaja',
  'New Device User': 'Novi korisnik uređaja',
  'Site ID': 'ID stranice',
  'Device ID': 'ID uređaja',
  Customer: 'Kupac',
  'Customer no & OTP': 'Broj kupca i OTP',
  'New OTP': 'Novi OTP',
  'Delete Device User': 'Izbriši korisnika uređaja',
  WARNING: 'UPOZORENJE',
  'When pressing OK, you will deactivate the current device paired with this location': 'Kada pritisnete OK, deaktivirat ćete trenutni uređaj uparen s ovom lokacijom',
  'If there is data on the current device, this data will be lost after pressing OK': 'Ako postoje podaci na trenutnom uređaju, ti će se podaci izgubiti nakon pritiska na OK',
  Edit: 'Uredi',
  Sites: 'Stranice',
  'Updated at': 'Ažurirano u',
  'Are you sure you want to delete the site? The action can not be undone': 'Jeste li sigurni da želite izbrisati web mjesto? Radnja se ne može poništiti',
  Previous: 'Prethodno',
  Next: 'Sljedeći',
  Units: 'Jedinice',
  'Get OTP code': 'Nabavite OTP kod',
  'Activate OTP code for': 'Aktivirajte OTP kod za',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Kada pritisnete OK, deaktivirat ćete trenutnu jedinicu pridruženu ovoj lokaciji',
  'You will se the new OTP code once you click OK': 'Vidjet ćete novi OTP kod kada kliknete OK',
  Workers: 'Radnici',
  'Create Worker': 'Stvori radnika',
  'Edit Worker': 'Uredi radnika',
  'Delete Worker': 'Izbriši radnika',
  'Are you sure you want to delete the worker': 'Jeste li sigurni da želite izbrisati radnika',
  'Select Worker': 'Odaberite Radnik',
  'Save Worker': 'Spasiti radnika',
  Advanced: 'Administrator',
  'Application Settings': 'Postavke aplikacije',
  'User management': 'Upravljanje korisnicima',
  'Change password': 'Promjena lozinke',
  Logout: 'Odjavite se',
  'Profile settings': 'Postavke profila',
  Timezone: 'Vremenska zona',
  Language: 'Jezik',
  'Force users to use 2-f-auth': 'Prisilite korisnike da koriste 2-f-auth',
  'Old password': 'Stara lozinka',
  'New password': 'Nova lozinka (najmanje 6 znakova)',
  'New password confirmation': 'Potvrda nove lozinke (najmanje 6 znakova)',
  'Confirm new password': 'Potvrdite novu lozinku (najmanje 6 znakova)',
  'Enter new password': 'Unesite novu lozinku (najmanje 6 znakova)',
  'Enter old password': 'Unesite staru lozinku',
  'New User': 'Novi korisnik',
  'Edit User': 'Uredi korisnika',
  'Left empty if want to keep old': 'Ostavljeno prazno ako želite zadržati staro',
  Username: 'Korisničko ime',
  Email: 'E-mail',
  Status: 'Status',
  'change will logout you from the application': 'promjena će vas odjaviti iz aplikacije',
  'Remove google authenticator secret key': 'Uklonite tajni ključ google autentifikatora',
  'Remove PSK': 'Uklonite PSK',
  'Full Name': 'Puno ime',
  'Delete user': 'Izbriši korisnika',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Otvorite Google Authenticator i dodajte Eform aplikaciju skeniranjem QR koda s desne strane',
  'Enter the 6 digit code that Google Authenticator generates': 'Unesite 6-znamenkasti kod koji generira Google Authenticator',
  General: 'Općenito',
  Find: 'Pronaći',
  'Nothing found': 'Ništa nije pronađeno',
  'Type in search box to see results': 'Upišite u okvir za pretraživanje kako biste vidjeli rezultate',
  'Select tags for filter': 'Odaberite oznake za filtar',
  'All selected': 'Sve odabrano',
  'Showing to of records': 'Prikazuje se {{currentPageAmount}} do {{currentPage}} od {{total}} zapisa',
  'Google Authenticator description': '<p>Tajni ključ između <strong>Eform</strong> aplikacije i <strong>Google Authenticatora</strong> . Čuvajte ga na sigurnom i nemojte nikome reći.</p><p> Trebali biste ukloniti ovaj ključ ako se <strong>Google Authenticator</strong> ponovno instalira ili je ključ uklonjen</p>',
  'New OTP for': 'Novi OTP za',
  Loading: 'Učitavam',
  Pending: 'U tijeku',
  Upload: 'Učitaj',
  Role: 'Uloga',
  'New list': 'Novi popis',
  'Name is required': 'Ime je potrebno',
  'Edit searchable list': 'Uredite pretraživi popis',
  'eForm Pairing': 'eForm uparivanje',
  'Tag for removal': 'Oznaka za uklanjanje',
  'New tags': 'Nove oznake',
  'Rotate on 90': 'Rotirajte za 90',
  'Delete picture': 'Izbriši sliku',
  'Open location': 'Otvorena lokacija',
  'File has been uploaded successfully': 'Datoteka je uspješno učitana',
  'Error while uploading file': 'Pogreška prilikom učitavanja datoteke',
  Customers: 'Kupci',
  'Do you want to leave this page': 'Želite li napustiti ovu stranicu',
  'If you leave before saving your changes will be lost': 'Ako odete prije spremanja, promjene će biti izgubljene',
  'Save & Leave': 'Spremi i ostavi',
  Cases: 'Slučajevi',
  Calendar: 'Kalendar',
  'Case management': 'Upravljanje slučajevima',
  Security: 'Sigurnost',
  'Security Groups': 'Sigurnosne grupe',
  GroupName: 'Grupno ime',
  UserAmount: 'Broj korisnika',
  'Edit Group': 'Uredi grupu',
  Permissions: 'Dozvole',
  'Delete Group': 'Izbriši grupu',
  'New Group': 'Nova grupa',
  'Create Group': 'Napraviti grupu',
  Group: 'Skupina',
  'Create Security Group': 'Stvorite sigurnosnu grupu',
  'Group Name': 'Grupno ime',
  'Select User': 'Odaberite Korisnik',
  'Bind to Group': 'Poveži se s grupom',
  Create: 'Stvoriti',
  Close: 'Zatvoriti',
  'Remove user from group': 'Ukloni korisnika iz grupe',
  UserManagement: 'Upravljanje korisnicima',
  Update: 'Ažuriraj',
  Read: 'Čitati',
  'General Permissions': 'Opća dopuštenja',
  Column: 'Stupac',
  'Eform Permissions': 'Eform dopuštenja',
  'Add Eform to Group': 'Dodaj Eform grupi',
  Forbidden: 'Zabranjeno',
  'Add user to group': 'Dodaj korisnika u grupu',
  'Update columns': 'Ažurirajte stupce',
  'Download XML': 'Preuzmite XML',
  'Upload ZIP': 'Upload ZIP',
  'Pairing read': 'Čitaj uparivanje',
  'Pairing update': 'Ažuriranje uparivanja',
  'Read tags': 'Čitaj oznake',
  'Update tags': 'Ažurirajte oznake',
  'Get CSV': 'Nabavite CSV',
  'Get DOCX': 'Preuzmite DOCX',
  'Get PDF': 'Preuzmite PDF',
  'Get PPTX': 'Nabavite PPTX',
  'Cases read': 'Slučajevi čitaju',
  'Case read': 'Slučaj pročitan',
  'Case update': 'Ažuriranje slučaja',
  'Case delete': 'Brisanje slučaja',
  'Bind Eform': 'Vezati Eform',
  'Read Jasper report': 'Pročitajte Jasperovo izvješće',
  'Update Jasper report': 'Ažurirajte Jasper izvješće',
  Display: 'Prikaz',
  Enabled: 'Omogućeno',
  Disabled: 'Onemogućeno',
  'Plugins Settings': 'Dodaci',
  PluginsSettings: 'Dodaci',
  'Edit Settings': 'Uredi postavke',
  'Connection String': 'Niz veze',
  Version: 'Verzija',
  'Header Picture': 'Slika zaglavlja',
  Preview: 'Pregled',
  Commentary: 'Komentar',
  Text: 'Tekst',
  'Selected Value': 'Odabrana vrijednost',
  'PDF Link': 'PDF Link',
  Information: 'Informacija',
  Duration: 'Trajanje',
  Audio: 'Audio',
  'Are you sure you want to install plugin': 'Jeste li sigurni da želite instalirati dodatak',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Ovo može potrajati do 10 minuta i prepisati trenutnu verziju dodatka ako već postoji u sustavu',
  Installation: 'Montaža',
  Install: 'Instalirati',
  Marketplace: 'Tržnica',
  'Plugins marketplace': 'Tržište dodataka',
  'Plugins Management': 'Upravljanje dodacima',
  'Warning: you will be logged out when status is changed': 'Upozorenje: bit ćete odjavljeni kada se status promijeni',
  'Select tags for site': 'Odaberite oznake za web mjesto',
  'Add new': 'Dodaj novi',
  'Email Recipients': 'Primatelji e-pošte',
  'Manage tags': 'Upravljanje oznakama',
  'Add Recipients': 'Dodajte primatelje',
  'Edit email recipient': 'Uredi primatelja e-pošte',
  'Delete email recipient': 'Izbriši primatelja e-pošte',
  'No email recipients found': 'Nije pronađen nijedan primatelj e-pošte',
  'New Email recipients': 'Novi primatelji e-pošte',
  'Email addresses': 'Email adrese',
  'Edit tag name': 'Uredi naziv oznake',
  'Tags management': 'Upravljanje oznakama',
  'Email/name text area should have at least one email/name pair': 'Područje teksta e-pošte/ime treba sadržavati barem jedan par e-pošte/ime',
  'SendGrid Key': 'Ključ SendGrid',
  'SendGrid Information': 'SendGrid informacije',
  'Saved locally': 'Spremljeno lokalno',
  'Ready for server': 'Spremno za server',
  'Received by server': 'Primio poslužitelj',
  'Retrieved by device': 'Dohvaćeno uređajem',
  Completed: 'Završeno',
  'Case posts': 'Stupovi za slučajeve',
  'No posts found': 'Nema pronađenih postova',
  'eForm conducted by': 'eForm provodi',
  'New post': 'Nova objava',
  'Created by': 'Napravio',
  To: 'Do',
  Subject: 'Predmet',
  'Email text': 'tekst e-pošte',
  'Link to case': 'Link na slučaj',
  'Attach PDF': 'Priloži PDF',
  'Post created by': 'Objavu izradio',
  'Post sent to': 'Post poslan na',
  Location: 'Mjesto',
  'InSight Version': 'InSight verzija',
  'eForm Version': 'eForm verzija',
  'Sync delay': 'Odgoda sinkronizacije',
  'Activate sync delay': 'Aktiviraj odgodu sinkronizacije',
  'Sync dialog': 'Dijalog sinkronizacije',
  'Activate sync dialog': 'Aktiviraj dijaloški okvir sinkronizacije',
  Push: 'Gurnuti',
  'Activate push': 'Aktivirajte guranje',
  'Move unit': 'Premjesti jedinicu',
  'Create unit': 'Stvorite jedinicu',
  'Select location': 'Odaberite mjesto',
  Move: 'Potez',
  'Current tags': 'Trenutačne oznake',
  'Edit selectable element': 'Uredi element koji se može odabrati',
  'Edit selectable list': 'Uredi popis koji se može odabrati',
  Folders: 'mape',
  Description: 'Opis',
  'Create folder': 'Stvori mapu',
  'Storage information': 'Podaci o pohrani',
  'Swift enabled': 'Swift je omogućen',
  'User name': 'Korisničko ime',
  'Swift endpoint': 'Brza krajnja točka',
  'Keystone endpoint': 'Keystone krajnja točka',
  'S3 enabled': 'S3 omogućen',
  'S3 accessKey': 'S3 pristupni ključ',
  'S3 secretAccessKey': 'S3 tajni pristupni ključ',
  'S3 endpoint': 'S3 krajnja točka',
  'S3 bucket name': 'Naziv korpe S3',
  'File location picture': 'Slika lokacije datoteke',
  'File location PDF': 'Lokacija datoteke PDF',
  'File location Jasper report files': 'Lokacija datoteke Jasper datoteke izvješća',
  'Http server address': 'Adresa Http poslužitelja',
  'SDK information': 'SDK informacije',
  'Customer no': 'br. kupca',
  Management: 'Upravljanje',
  'Update security group': 'Ažurirajte sigurnosnu grupu',
  'eForms permissions': 'dopuštenja za eForms',
  'Menu editor': 'Uređivač izbornika',
  'Edit menu entry': 'Uredi unos izbornika',
  Link: 'Veza',
  'Are you sure you want to delete menu entry': 'Jeste li sigurni da želite izbrisati unos izbornika',
  'New dropdown': 'Novi padajući izbornik',
  Dropdown: 'Padajući izbornik',
  'Security groups': 'Sigurnosne grupe',
  Custom: 'Prilagođen',
  Add: 'Dodati',
  'Generate report': 'Generiraj izvješće {{value}}',
  'Select date range': 'Odaberite razdoblje',
  'Edit headers': 'Uredite zaglavlja',
  'Text (TextSingleLine)': 'Tekst u jednom redu',
  Generate: 'Stvorite odnos',
  CheckBox: 'Potvrdni okvir',
  SingleSelect: 'Padajući izbornik',
  Comment: 'Tekst',
  Number: 'Numerički',
  Picture: 'Slika',
  None: 'Infokutija',
  Date: 'Datum',
  SaveButton: 'Uštedjeti',
  ShowPDF: 'PDF',
  FieldGroup: 'Skupina',
  NumberStepper: 'Steper',
  Signature: 'Potpis',
  Timer: 'StartStopTime',
  EntitySearch: 'Padajuće pretraživanje (moji popisi)',
  EntitySelect: 'Padajući izbornik (moji popisi)',
  MultiSelect: 'Višestruki odabir',
  'Save eForm.': 'Spremi eForm',
  'Server date': 'Datum poslužitelja',
  'eForm Pairing for': 'eForm Uparivanje za',
  'Select Site': 'Odaberite web mjesto',
  Select: 'Izaberi',
  Employee: 'Zaposlenik',
  Site: 'stranica',
  'Selected language': 'Odabrani jezik',
  'New folder': 'Nova mapa',
  Back: 'leđa',
  Danish: 'danski',
  English: 'Engleski',
  German: 'njemački',
  Spanish: 'španjolski',
  French: 'francuski',
  Italian: 'talijanski',
  Polish: 'Polirati',
  Portuguese: 'Portugalski',
  'Brazilian Portuguese': 'brazilski portugalski',
  Ukrainian: 'ukrajinski',
  Swedish: 'švedski',
  Norwegian: 'norveški',
  Dutch: 'nizozemski',
  Finish: 'Završi',
  'Done at': 'Dovršeno u',
  'Select date': 'Odaberite datum',
  Value: 'Vrijednost',
  'Please fill out this field': 'Molimo ispunite ovo polje',
  'Submitted date': 'Datum predaje',
  Languages: 'Jezici',
};
