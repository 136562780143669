<mat-tree *ngIf="menu.data" [dataSource]="menu" [treeControl]="treeControl" class="mt-2 ml-2">
  <mat-tree-node
    [style.width]="node.name.length <=36 ? '100%' : 'unset'"
    *matTreeNodeDef="let node"
    [matTreeNodePaddingIndent]="20"
    matTreeNodePadding="">
    <ng-container *ngIf="checkGuards(node.guards) | async">
      <!-- use a disabled button to provide padding for tree leaf -->
      <a mat-button
         style="width: 100%"
         color="{{this.router.url === node.link ? 'accent' : ''}}"
         *ngIf="node.isInternalLink"
         routerLink="{{ node.link }}"
         class="nav-link"
         [id]="node.e2EId"
         (click)="onClickOnNode()"
      >
        {{ node.name }}
      </a>
      <a mat-button
         *ngIf="!node.isInternalLink"
         class="nav-link"
         target="_blank"
         [href]="node.link"
         (click)="onClickOnNode()"
      >
        {{node.name }}
      </a>
    </ng-container>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding="">
    <mat-accordion *ngIf="checkGuards(node.guards)">
      <mat-expansion-panel [expanded]="treeControl.isExpanded(node)">
        <mat-expansion-panel-header>
          <a
            style="text-align: left; font-size: 14px;"
            matTreeNodeToggle
            [id]="node.e2EId"
          >
            <span style="">{{node.name}}</span>
          </a>
        </mat-expansion-panel-header>
        <div style="overflow-x: auto;">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-nested-tree-node>
</mat-tree>
