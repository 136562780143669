export const frFR = {
  Settings: 'Paramètres',
  Save: 'Sauvegarder',
  'Login Page': 'Page de connexion',
  Reset: 'Réinitialiser',
  'Main Text': 'Texte principal',
  'Secondary Text': 'Texte secondaire',
  Image: 'Image',
  'Recommended parameters': 'Paramètres recommandés',
  Resolution: 'Résolution',
  Proportions: 'Proportions',
  'Site header': 'En-tête du site',
  Logo: 'Logo',
  'SMTP Information': 'Informations SMTP',
  Host: 'Hôte',
  Port: 'Port',
  Login: 'Se connecter',
  Password: 'Mot de passe',
  'Forgot password': 'Mot de passe oublié',
  'Restore password': 'Restaurer le mot de passe',
  'Reset admin password': 'Réinitialiser le mot de passe administrateur',
  'Back to login': 'Retour connexion',
  'Reset password': 'Réinitialiser le mot de passe',
  Approved: 'Approuvé',
  Review: 'Revoir',
  'Not approved': 'Non approuvé',
  'Updating cases, please wait': 'Mise à jour des cas, veuillez patienter',
  Search: 'Recherche',
  done_at: 'Formulaire électronique terminé à',
  created_at: 'Données accessibles sur',
  worker_name: 'Formulaire électronique réalisé par',
  'Edit Case': 'Modifier le cas',
  'Delete Case': 'Supprimer le cas',
  Download: 'Télécharger',
  'Are you sure you want to delete this case': 'Êtes-vous sûr de vouloir supprimer ce cas',
  'Accessible at': 'Accessible à',
  'Finished at': 'Terminé à',
  'Done by': 'Fait par',
  OK: 'D&#39;ACCORD',
  Cancel: 'Annuler',
  'Edit Searchable item': 'Modifier l&#39;élément consultable',
  'Delete Searchable item': 'Supprimer l&#39;élément consultable',
  'Edit searchable element': 'Modifier l&#39;élément consultable',
  Name: 'Nom',
  Ok: 'D&#39;accord',
  'Import options (max 100 entries)': 'Options d&#39;importation (max 100 entrées)',
  Import: 'Importer',
  'My eForms': 'Mes formulaires électroniques',
  'Create eForm': 'Créer un formulaire électronique',
  'New eForm': 'Nouveau formulaire électronique',
  'New eForm from XML': 'Nouveau formulaire électronique à partir de XML',
  Label: 'Étiquette',
  Tags: 'Mots clés',
  CreatedAt: 'Créé à',
  'eForm Name': 'Nom du formulaire électronique',
  Pairing: 'Couplage',
  Actions: 'Actions',
  'Edit tags': 'Étiquettes d&#39;édition',
  'Click to edit eForm pairing with user(s)': 'Cliquez pour modifier le couplage du formulaire électronique avec les utilisateurs',
  'Edit pairing': 'Modifier l&#39;association',
  'Click to pair eForm with user(s)': 'Cliquez pour associer le formulaire électronique à un ou plusieurs utilisateurs',
  'Pair eForm': 'Associer un formulaire électronique',
  'Show cases': 'Vitrines',
  'Edit columns': 'Modifier les colonnes',
  'Download CSV': 'Télécharger CSV',
  'Delete eForm': 'Supprimer le formulaire électronique',
  'Upload ZIP archive': 'Télécharger l&#39;archive ZIP',
  'Upload template ZIP': 'Télécharger le modèle ZIP',
  'Uploading progress': 'Progression du téléchargement',
  'Save eForm': 'Enregistrer le formulaire électronique',
  'Device user': 'Utilisateur de l&#39;appareil',
  'Check to pair': 'Cochez pour jumeler',
  'Save pairing': 'Enregistrer le jumelage',
  'Select columns for displaying in Cases': 'Sélectionnez les colonnes à afficher dans les requêtes',
  Field: 'Champ',
  'Are you sure you want to delete': 'Etes-vous sûr que vous voulez supprimer',
  'Creation Date': 'Date de création',
  'Select tags for template': 'Sélectionnez les balises pour le modèle',
  'Template tags': 'Balises de modèle',
  'New tag': 'Nouvelle balise',
  'Tag list': 'Liste des balises',
  'Add field': 'Ajouter le champ',
  'Add sub eForm': 'Ajouter un sous-formulaire électronique',
  'Edit sub eForm': 'Modifier le sous-formulaire électronique',
  'New sub eForm': 'Nouveau sous-formulaire électronique',
  'New field': 'Nouveau champ',
  'Field is not complete': 'Le champ n&#39;est pas complet',
  'Add new nested field': 'Ajouter un champ au groupe',
  'Copy field': 'Copier le champ',
  'Edit field': 'Modifier le champ',
  'Delete field': 'Supprimer le champ',
  'Expand field': 'Afficher les champs dans le groupe',
  'Collapse field': 'Masquer les champs dans le groupe',
  Mandatory: 'Obligatoire',
  Type: 'Taper',
  'Are you sure you want to delete field': 'Etes-vous sûr de vouloir supprimer le champ',
  'eForm is not complete': 'Le formulaire électronique n&#39;est pas complet',
  'Edit eForm': 'Modifier le formulaire électronique',
  'New nested field': 'Nouveau champ imbriqué',
  Delete: 'Supprimer',
  'XML from eForm Builder': 'XML à partir du générateur de formulaires électroniques',
  'Paste XML here': 'Collez le XML ici',
  'Creation date': 'Date de création',
  'Searchable lists': 'Listes consultables',
  'Searchable list': 'Liste consultable',
  'Create new searchable list': 'Créer une nouvelle liste consultable',
  'Edit site': 'Modifier le site',
  'Selectable lists': 'Listes sélectionnables',
  'Selectable list': 'Liste sélectionnable',
  'Create new selectable list': 'Créer une nouvelle liste sélectionnable',
  'Terms of Service': 'Conditions d&#39;utilisation',
  'Privacy Policy': 'politique de confidentialité',
  'Data Protection Terms (GDPR)': 'Conditions de protection des données (RGPD)',
  'Save password': 'Enregistrer le mot de passe',
  'Edit Device User': 'Modifier l&#39;utilisateur de l&#39;appareil',
  'First name': 'Prénom',
  'Last name': 'Nom de famille',
  'Device Users': 'Utilisateurs d&#39;appareils',
  'Create Device User': 'Créer un utilisateur de périphérique',
  'New Device User': 'Nouvel utilisateur de l&#39;appareil',
  'Site ID': 'ID du site',
  'Device ID': 'Reference de l&#39;appareil',
  Customer: 'Client',
  'Customer no & OTP': 'Numéro de client et OTP',
  'New OTP': 'Nouveau Bureau du Procureur',
  'Delete Device User': 'Supprimer l&#39;utilisateur de l&#39;appareil',
  WARNING: 'AVERTISSEMENT',
  'When pressing OK, you will deactivate the current device paired with this location': 'En appuyant sur OK, vous désactiverez l&#39;appareil actuel associé à cet emplacement',
  'If there is data on the current device, this data will be lost after pressing OK': 'S&#39;il y a des données sur l&#39;appareil actuel, ces données seront perdues après avoir appuyé sur OK',
  Edit: 'Modifier',
  Sites: 'Des sites',
  'Updated at': 'Mis à jour à',
  'Are you sure you want to delete the site? The action can not be undone': 'Êtes-vous sûr de vouloir supprimer le site ? L&#39;action ne peut pas être annulée',
  Previous: 'Précédent',
  Next: 'Suivant',
  Units: 'Unités',
  'Get OTP code': 'Obtenir le code OTP',
  'Activate OTP code for': 'Activer le code OTP pour',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'En appuyant sur OK, vous désactiverez l&#39;unité actuelle associée à cet emplacement',
  'You will se the new OTP code once you click OK': 'Vous verrez le nouveau code OTP une fois que vous aurez cliqué sur OK',
  Workers: 'Ouvriers',
  'Create Worker': 'Créer un travailleur',
  'Edit Worker': 'Modifier le travailleur',
  'Delete Worker': 'Supprimer le travailleur',
  'Are you sure you want to delete the worker': 'Êtes-vous sûr de vouloir supprimer le travailleur',
  'Select Worker': 'Sélectionner un travailleur',
  'Save Worker': 'Sauver le travailleur',
  Advanced: 'Administrateur',
  'Application Settings': 'Paramètres de l&#39;application',
  'User management': 'Gestion des utilisateurs',
  'Change password': 'Changer le mot de passe',
  Logout: 'Se déconnecter',
  'Profile settings': 'Paramètres de profil',
  Timezone: 'Fuseau horaire',
  Language: 'Langue',
  'Force users to use 2-f-auth': 'Forcer les utilisateurs à utiliser l&#39;authentification 2-f',
  'Old password': 'Ancien mot de passe',
  'New password': 'Nouveau mot de passe (min 6 caractères)',
  'New password confirmation': 'Confirmation du nouveau mot de passe (min 6 caractères)',
  'Confirm new password': 'Confirmer le nouveau mot de passe (min 6 caractères)',
  'Enter new password': 'Entrez le nouveau mot de passe (min 6 caractères)',
  'Enter old password': 'Entrez l&#39;ancien mot de passe',
  'New User': 'Nouvel utilisateur',
  'Edit User': 'Modifier l&#39;utilisateur',
  'Left empty if want to keep old': 'Laissé vide si je veux garder l&#39;ancien',
  Username: 'Nom d&#39;utilisateur',
  Email: 'E-mail',
  Status: 'Statut',
  'change will logout you from the application': 'le changement vous déconnectera de l&#39;application',
  'Remove google authenticator secret key': 'Supprimer la clé secrète de l&#39;authentificateur Google',
  'Remove PSK': 'Supprimer PSK',
  'Full Name': 'Nom et prénom',
  'Delete user': 'Supprimer l&#39;utilisateur',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Ouvrez Google Authenticator et ajoutez l&#39;application Eform en scannant le QR Code à droite',
  'Enter the 6 digit code that Google Authenticator generates': 'Entrez le code à 6 chiffres généré par Google Authenticator',
  General: 'Général',
  Find: 'Trouver',
  'Nothing found': 'Rien n&#39;a été trouvé',
  'Type in search box to see results': 'Tapez dans le champ de recherche pour voir les résultats',
  'Select tags for filter': 'Sélectionnez les balises pour le filtre',
  'All selected': 'Tous sélectionnés',
  'Showing to of records': 'Affichage de {{currentPageAmount}} à {{currentPage}} sur {{total}} enregistrements',
  'Google Authenticator description': '<p>Clé secrète entre l&#39;application <strong>Eform</strong> et <strong>Google Authenticator</strong> . Gardez-le en sécurité et ne le dites à personne.</p><p> Vous devez supprimer cette clé si <strong>Google Authenticator</strong> est réinstallé ou si la clé a été supprimée</p>',
  'New OTP for': 'Nouvel OTP pour',
  Loading: 'Chargement',
  Pending: 'En attente',
  Upload: 'Télécharger',
  Role: 'Rôle',
  'New list': 'Nouvelle liste',
  'Name is required': 'Le nom est requis',
  'Edit searchable list': 'Modifier la liste consultable',
  'eForm Pairing': 'Couplage de formulaires électroniques',
  'Tag for removal': 'Étiquette à supprimer',
  'New tags': 'Nouvelles balises',
  'Rotate on 90': 'Rotation sur 90',
  'Delete picture': 'Supprimer l&#39;image',
  'Open location': 'Emplacement ouvert',
  'File has been uploaded successfully': 'Le fichier a été téléchargé avec succès',
  'Error while uploading file': 'Erreur lors du téléchargement du fichier',
  Customers: 'Clients',
  'Do you want to leave this page': 'Voulez-vous quitter cette page',
  'If you leave before saving your changes will be lost': 'Si vous quittez avant d&#39;enregistrer vos modifications seront perdues',
  'Save & Leave': 'Enregistrer et partir',
  Cases: 'Cas',
  Calendar: 'Calendrier',
  'Case management': 'La gestion de cas',
  Security: 'Sécurité',
  'Security Groups': 'Groupes de sécurité',
  GroupName: 'Nom de groupe',
  UserAmount: 'Montant des utilisateurs',
  'Edit Group': 'Modifier le groupe',
  Permissions: 'Autorisations',
  'Delete Group': 'Supprimer le groupe',
  'New Group': 'Nouveau groupe',
  'Create Group': 'Créer un groupe',
  Group: 'Groupe',
  'Create Security Group': 'Créer un groupe de sécurité',
  'Group Name': 'Nom de groupe',
  'Select User': 'Sélectionnez un utilisateur',
  'Bind to Group': 'Lier au groupe',
  Create: 'Créer',
  Close: 'Fermer',
  'Remove user from group': 'Supprimer l&#39;utilisateur du groupe',
  UserManagement: 'Gestion des utilisateurs',
  Update: 'Mise à jour',
  Read: 'Lire',
  'General Permissions': 'Autorisations générales',
  Column: 'Colonne',
  'Eform Permissions': 'Autorisations de formulaire électronique',
  'Add Eform to Group': 'Ajouter un formulaire électronique au groupe',
  Forbidden: 'Interdit',
  'Add user to group': 'Ajouter un utilisateur au groupe',
  'Update columns': 'Mettre à jour les colonnes',
  'Download XML': 'Télécharger XML',
  'Upload ZIP': 'Télécharger le code postal',
  'Pairing read': 'Lecture d&#39;appariement',
  'Pairing update': 'Mise à jour du couplage',
  'Read tags': 'Lire les balises',
  'Update tags': 'Mettre à jour les balises',
  'Get CSV': 'Obtenir un fichier CSV',
  'Get DOCX': 'Obtenir DOCX',
  'Get PDF': 'Obtenir le PDF',
  'Get PPTX': 'Obtenez PPTX',
  'Cases read': 'Cas lus',
  'Case read': 'Cas lu',
  'Case update': 'Mise à jour du cas',
  'Case delete': 'Suppression du cas',
  'Bind Eform': 'Lier le formulaire électronique',
  'Read Jasper report': 'Lire le rapport Jasper',
  'Update Jasper report': 'Mettre à jour le rapport Jasper',
  Display: 'Afficher',
  Enabled: 'Activé',
  Disabled: 'Désactivé',
  'Plugins Settings': 'Plugins',
  PluginsSettings: 'Plugins',
  'Edit Settings': 'Modifier les paramètres',
  'Connection String': 'Chaîne de connexion',
  Version: 'Version',
  'Header Picture': 'Image d&#39;en-tête',
  Preview: 'Aperçu',
  Commentary: 'Commentaire',
  Text: 'Texte',
  'Selected Value': 'Valeur sélectionnée',
  'PDF Link': 'Lien PDF',
  Information: 'Information',
  Duration: 'Durée',
  Audio: 'l&#39;audio',
  'Are you sure you want to install plugin': 'Êtes-vous sûr de vouloir installer le plugin',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Cela peut prendre jusqu&#39;à 10 minutes et réécrire la version actuelle du plugin si elle existe déjà dans le système',
  Installation: 'Installation',
  Install: 'Installer',
  Marketplace: 'Marché',
  'Plugins marketplace': 'Marché des plugins',
  'Plugins Management': 'Gestion des plugins',
  'Warning: you will be logged out when status is changed': 'Attention : vous serez déconnecté lorsque le statut sera modifié',
  'Select tags for site': 'Sélectionnez les balises pour le site',
  'Add new': 'Ajouter un nouveau',
  'Email Recipients': 'Destinataires des e-mails',
  'Manage tags': 'Gérer les balises',
  'Add Recipients': 'Ajouter des destinataires',
  'Edit email recipient': 'Modifier le destinataire de l&#39;e-mail',
  'Delete email recipient': 'Supprimer le destinataire de l&#39;e-mail',
  'No email recipients found': 'Aucun destinataire de courrier électronique trouvé',
  'New Email recipients': 'Nouveaux destinataires de courrier électronique',
  'Email addresses': 'Adresses mail',
  'Edit tag name': 'Modifier le nom de la balise',
  'Tags management': 'Gestion des balises',
  'Email/name text area should have at least one email/name pair': 'La zone de texte e-mail/nom doit contenir au moins une paire e-mail/nom',
  'SendGrid Key': 'Clé d&#39;envoi de grille',
  'SendGrid Information': 'Informations sur la grille d&#39;envoi',
  'Saved locally': 'Enregistré localement',
  'Ready for server': 'Prêt pour le serveur',
  'Received by server': 'Reçu par le serveur',
  'Retrieved by device': 'Récupéré par appareil',
  Completed: 'Complété',
  'Case posts': 'Messages de cas',
  'No posts found': 'aucun article trouvé',
  'eForm conducted by': 'Formulaire électronique réalisé par',
  'New post': 'Nouveau poste',
  'Created by': 'Créé par',
  To: 'À',
  Subject: 'Sujet',
  'Email text': 'texte d&#39;e-mail',
  'Link to case': 'Lien vers le cas',
  'Attach PDF': 'Joindre un PDF',
  'Post created by': 'Article créé par',
  'Post sent to': 'Message envoyé à',
  Location: 'Emplacement',
  'InSight Version': 'Version Aperçu',
  'eForm Version': 'Version du formulaire électronique',
  'Sync delay': 'Délai de synchronisation',
  'Activate sync delay': 'Activer le délai de synchronisation',
  'Sync dialog': 'Boîte de dialogue de synchronisation',
  'Activate sync dialog': 'Activer la boîte de dialogue de synchronisation',
  Push: 'Pousser',
  'Activate push': 'Activer le push',
  'Move unit': 'Déplacer l&#39;unité',
  'Create unit': 'Créer une unité',
  'Select location': 'Sélectionnez l&#39;emplacement',
  Move: 'Se déplacer',
  'Current tags': 'Balises actuelles',
  'Edit selectable element': 'Modifier l&#39;élément sélectionnable',
  'Edit selectable list': 'Modifier la liste sélectionnable',
  Folders: 'Dossiers',
  Description: 'Description',
  'Create folder': 'Créer le dossier',
  'Storage information': 'Informations de stockage',
  'Swift enabled': 'Swift activé',
  'User name': 'Nom d&#39;utilisateur',
  'Swift endpoint': 'Point de terminaison Swift',
  'Keystone endpoint': 'Point de terminaison Keystone',
  'S3 enabled': 'S3 activé',
  'S3 accessKey': 'Clé d&#39;accès S3',
  'S3 secretAccessKey': 'Clé d&#39;accès secrète S3',
  'S3 endpoint': 'Point de terminaison S3',
  'S3 bucket name': 'Nom du compartiment S3',
  'File location picture': 'Image de l&#39;emplacement du fichier',
  'File location PDF': 'Emplacement du fichier PDF',
  'File location Jasper report files': 'Emplacement des fichiers Fichiers de rapport Jasper',
  'Http server address': 'Adresse du serveur HTTP',
  'SDK information': 'Informations sur le SDK',
  'Customer no': 'Numéro de client',
  Management: 'Gestion',
  'Update security group': 'Mettre à jour le groupe de sécurité',
  'eForms permissions': 'Autorisations des formulaires électroniques',
  'Menu editor': 'Éditeur de menus',
  'Edit menu entry': 'Modifier l&#39;entrée du menu',
  Link: 'Lien',
  'Are you sure you want to delete menu entry': 'Êtes-vous sûr de vouloir supprimer l&#39;entrée de menu',
  'New dropdown': 'Nouveau menu déroulant',
  Dropdown: 'Dérouler',
  'Security groups': 'Groupes de sécurité',
  Custom: 'Coutume',
  Add: 'Ajouter',
  'Generate report': 'Générer un rapport {{value}}',
  'Select date range': 'Sélectionnez une plage de dates',
  'Edit headers': 'Modifier les en-têtes',
  'Text (TextSingleLine)': 'Texte sur une seule ligne',
  Generate: 'Générer du rapport',
  CheckBox: 'Case à cocher',
  SingleSelect: 'Dérouler',
  Comment: 'Texte',
  Number: 'Numérique',
  Picture: 'Image',
  None: 'Boîte d&#39;info',
  Date: 'Date',
  SaveButton: 'Sauvegarder',
  ShowPDF: 'PDF',
  FieldGroup: 'Groupe',
  NumberStepper: 'Pas à pas',
  Signature: 'Signature',
  Timer: 'DébutArrêtHeure',
  EntitySearch: 'Recherche déroulante (mes listes)',
  EntitySelect: 'Liste déroulante (mes listes)',
  MultiSelect: 'Sélection multiple',
  'Save eForm.': 'Enregistrer le formulaire électronique',
  'Server date': 'Date du serveur',
  'eForm Pairing for': 'Couplage de formulaires électroniques pour',
  'Select Site': 'Sélectionnez un site',
  Select: 'Sélectionner',
  Employee: 'Employé',
  Site: 'Site',
  'Selected language': 'Langue sélectionnée',
  'New folder': 'Nouveau dossier',
  Back: 'Dos',
  Danish: 'danois',
  English: 'Anglais',
  German: 'Allemand',
  Spanish: 'Espagnol',
  French: 'Français',
  Italian: 'italien',
  Polish: 'polonais',
  Portuguese: 'Portugais',
  'Brazilian Portuguese': 'portugais brésilien',
  Ukrainian: 'ukrainien',
  Swedish: 'suédois',
  Norwegian: 'norvégien',
  Dutch: 'Néerlandais',
  Finish: 'Finition',
  'Done at': 'Fait à',
  'Select date': 'Sélectionner une date',
  Value: 'Valeur',
  'Please fill out this field': 'Merci de remplir ce champ',
  'Submitted date': 'Date proposée',
  Languages: 'Langues',
};
