export const noNO = {
  Settings: 'Innstillinger',
  Save: 'Lagre',
  'Login Page': 'Påloggingsside',
  Reset: 'Nullstille',
  'Main Text': 'Hovedtekst',
  'Secondary Text': 'Sekundær tekst',
  Image: 'Bilde',
  'Recommended parameters': 'Anbefalte parametere',
  Resolution: 'Vedtak',
  Proportions: 'Proporsjoner',
  'Site header': 'Sideoverskrift',
  Logo: 'Logo',
  'SMTP Information': 'SMTP-informasjon',
  Host: 'Vert',
  Port: 'Havn',
  Login: 'Logg Inn',
  Password: 'Passord',
  'Forgot password': 'Glemt passord',
  'Restore password': 'Gjenopprett passord',
  'Reset admin password': 'Tilbakestill administratorpassord',
  'Back to login': 'Tilbake til innlogging',
  'Reset password': 'tilbakestille passord',
  Approved: 'Godkjent',
  Review: 'Anmeldelse',
  'Not approved': 'Ikke godkjent',
  'Updating cases, please wait': 'Oppdaterer saker, vennligst vent',
  Search: 'Søk',
  done_at: 'eForm ferdig kl',
  created_at: 'Data tilgjengelig på',
  worker_name: 'eForm utført av',
  'Edit Case': 'Rediger sak',
  'Delete Case': 'Slett sak',
  Download: 'nedlasting',
  'Are you sure you want to delete this case': 'Er du sikker på at du vil slette denne saken',
  'Accessible at': 'Tilgjengelig kl',
  'Finished at': 'Ferdig kl',
  'Done by': 'Gjøres ved å',
  OK: 'OK',
  Cancel: 'Avbryt',
  'Edit Searchable item': 'Rediger søkbart element',
  'Delete Searchable item': 'Slett søkbart element',
  'Edit searchable element': 'Rediger søkbart element',
  Name: 'Navn',
  Ok: 'Ok',
  'Import options (max 100 entries)': 'Importalternativer (maks. 100 oppføringer)',
  Import: 'Import',
  'My eForms': 'Mine e-skjemaer',
  'Create eForm': 'Lag eForm',
  'New eForm': 'Ny eForm',
  'New eForm from XML': 'Ny eForm fra XML',
  Label: 'Merkelapp',
  Tags: 'Tagger',
  CreatedAt: 'Opprettet kl',
  'eForm Name': 'eForm navn',
  Pairing: 'Sammenkobling',
  Actions: 'Handlinger',
  'Edit tags': 'Rediger tagger',
  'Click to edit eForm pairing with user(s)': 'Klikk for å redigere eForm-paring med bruker(e)',
  'Edit pairing': 'Rediger sammenkobling',
  'Click to pair eForm with user(s)': 'Klikk for å pare eForm med bruker(e)',
  'Pair eForm': 'Par eForm',
  'Show cases': 'Vis saker',
  'Edit columns': 'Rediger kolonner',
  'Download CSV': 'Last ned CSV',
  'Delete eForm': 'Slett eForm',
  'Upload ZIP archive': 'Last opp ZIP-arkiv',
  'Upload template ZIP': 'Last opp mal ZIP',
  'Uploading progress': 'Opplastingsfremdrift',
  'Save eForm': 'Lagre eForm',
  'Device user': 'Enhetsbruker',
  'Check to pair': 'Merk av for å pare',
  'Save pairing': 'Lagre sammenkobling',
  'Select columns for displaying in Cases': 'Velg kolonner for visning i saker',
  Field: 'Felt',
  'Are you sure you want to delete': 'Er du sikker på at du vil slette',
  'Creation Date': 'Opprettelsesdato',
  'Select tags for template': 'Velg tagger for mal',
  'Template tags': 'Mal-tagger',
  'New tag': 'Ny tag',
  'Tag list': 'Tag-liste',
  'Add field': 'Legg til felt',
  'Add sub eForm': 'Legg til under eForm',
  'Edit sub eForm': 'Rediger under eForm',
  'New sub eForm': 'Ny sub eForm',
  'New field': 'Nytt felt',
  'Field is not complete': 'Feltet er ikke komplett',
  'Add new nested field': 'Legg til felt i gruppen',
  'Copy field': 'Kopier feltet',
  'Edit field': 'Rediger felt',
  'Delete field': 'Slett felt',
  'Expand field': 'Vis felt i gruppe',
  'Collapse field': 'Skjul felt i gruppe',
  Mandatory: 'Påbudt, bindende',
  Type: 'Type',
  'Are you sure you want to delete field': 'Er du sikker på at du vil slette feltet',
  'eForm is not complete': 'eForm er ikke komplett',
  'Edit eForm': 'Rediger eForm',
  'New nested field': 'Nytt nestet felt',
  Delete: 'Slett',
  'XML from eForm Builder': 'XML fra eForm Builder',
  'Paste XML here': 'Lim inn XML her',
  'Creation date': 'Opprettelsesdato',
  'Searchable lists': 'Søkbare lister',
  'Searchable list': 'Søkbar liste',
  'Create new searchable list': 'Opprett ny søkbar liste',
  'Edit site': 'Rediger nettsted',
  'Selectable lists': 'Valgbare lister',
  'Selectable list': 'Valgbar liste',
  'Create new selectable list': 'Opprett ny valgbar liste',
  'Terms of Service': 'Vilkår for bruk',
  'Privacy Policy': 'Personvernerklæring',
  'Data Protection Terms (GDPR)': 'Vilkår for databeskyttelse (GDPR)',
  'Save password': 'Lagre passord',
  'Edit Device User': 'Rediger enhetsbruker',
  'First name': 'Fornavn',
  'Last name': 'Etternavn',
  'Device Users': 'Enhetsbrukere',
  'Create Device User': 'Opprett enhetsbruker',
  'New Device User': 'Ny enhetsbruker',
  'Site ID': 'Nettsted-ID',
  'Device ID': 'Enhets-ID',
  Customer: 'Kunde',
  'Customer no & OTP': 'Kundenummer &amp; OTP',
  'New OTP': 'Ny OTP',
  'Delete Device User': 'Slett enhetsbruker',
  WARNING: 'ADVARSEL',
  'When pressing OK, you will deactivate the current device paired with this location': 'Når du trykker OK, vil du deaktivere gjeldende enhet som er paret med denne plasseringen',
  'If there is data on the current device, this data will be lost after pressing OK': 'Hvis det er data på gjeldende enhet, vil disse dataene gå tapt etter å ha trykket på OK',
  Edit: 'Redigere',
  Sites: 'Nettsteder',
  'Updated at': 'Oppdatert kl',
  'Are you sure you want to delete the site? The action can not be undone': 'Er du sikker på at du vil slette nettstedet? Handlingen kan ikke angres',
  Previous: 'Tidligere',
  Next: 'Neste',
  Units: 'Enheter',
  'Get OTP code': 'Få engangskode',
  'Activate OTP code for': 'Aktiver engangskode for',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Når du trykker OK, vil du deaktivere gjeldende enhet knyttet til denne plasseringen',
  'You will se the new OTP code once you click OK': 'Du vil se den nye OTP-koden når du klikker OK',
  Workers: 'Arbeidere',
  'Create Worker': 'Opprett arbeider',
  'Edit Worker': 'Rediger arbeider',
  'Delete Worker': 'Slett arbeider',
  'Are you sure you want to delete the worker': 'Er du sikker på at du vil slette arbeideren',
  'Select Worker': 'Velg Arbeider',
  'Save Worker': 'Redd arbeider',
  Advanced: 'Admin',
  'Application Settings': 'Applikasjon innstillinger',
  'User management': 'Brukeradministrasjon',
  'Change password': 'Bytt passord',
  Logout: 'Logg ut',
  'Profile settings': 'Profilinnstillinger',
  Timezone: 'Tidssone',
  Language: 'Språk',
  'Force users to use 2-f-auth': 'Tving brukere til å bruke 2-f-auth',
  'Old password': 'Gammelt passord',
  'New password': 'Nytt passord (minst 6 tegn)',
  'New password confirmation': 'Nytt passordbekreftelse (minst 6 tegn)',
  'Confirm new password': 'Bekreft nytt passord (minst 6 tegn)',
  'Enter new password': 'Skriv inn nytt passord (minst 6 tegn)',
  'Enter old password': 'Skriv inn gammelt passord',
  'New User': 'Ny bruker',
  'Edit User': 'Rediger bruker',
  'Left empty if want to keep old': 'Forlatt tom hvis ønsker å beholde gamle',
  Username: 'Brukernavn',
  Email: 'E-post',
  Status: 'Status',
  'change will logout you from the application': 'endring vil logge deg ut av applikasjonen',
  'Remove google authenticator secret key': 'Fjern den hemmelige nøkkelen for Google Authenticator',
  'Remove PSK': 'Fjern PSK',
  'Full Name': 'Fullt navn',
  'Delete user': 'Slett bruker',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Åpne Google Authenticator og legg til Eform-applikasjonen ved å skanne QR-koden til høyre',
  'Enter the 6 digit code that Google Authenticator generates': 'Skriv inn den sekssifrede koden som Google Authenticator genererer',
  General: 'Generell',
  Find: 'Finne',
  'Nothing found': 'Ingenting funnet',
  'Type in search box to see results': 'Skriv inn søkefeltet for å se resultater',
  'Select tags for filter': 'Velg tagger for filter',
  'All selected': 'Alle valgt',
  'Showing to of records': 'Viser {{currentPageAmount}} til {{currentPage}} av {{total}} poster',
  'Google Authenticator description': '<p>Hemmelig nøkkel mellom <strong>Eform</strong> -applikasjonen og <strong>Google Authenticator</strong> . Hold itIT trygt og ikke fortell det til noen.</p><p> Du bør fjerne denne nøkkelen hvis <strong>Google Authenticator</strong> er installert på nytt eller nøkkelen ble fjernet</p>',
  'New OTP for': 'Ny OTP for',
  Loading: 'Laster',
  Pending: 'Avventer',
  Upload: 'Laste opp',
  Role: 'Rolle',
  'New list': 'Ny liste',
  'Name is required': 'Navn er påkrevd',
  'Edit searchable list': 'Rediger søkbar liste',
  'eForm Pairing': 'eForm-paring',
  'Tag for removal': 'Tag for fjerning',
  'New tags': 'Nye tagger',
  'Rotate on 90': 'Roter på 90',
  'Delete picture': 'Slett bildet',
  'Open location': 'Åpen plassering',
  'File has been uploaded successfully': 'Filen er lastet opp',
  'Error while uploading file': 'Feil under opplasting av fil',
  Customers: 'Kunder',
  'Do you want to leave this page': 'Vil du forlate denne siden',
  'If you leave before saving your changes will be lost': 'Hvis du forlater før du lagrer, vil endringene gå tapt',
  'Save & Leave': 'Lagre og forlat',
  Cases: 'Saker',
  Calendar: 'Kalender',
  'Case management': 'Saksbehandling',
  Security: 'Sikkerhet',
  'Security Groups': 'Sikkerhetsgrupper',
  GroupName: 'Gruppenavn',
  UserAmount: 'Brukerbeløp',
  'Edit Group': 'Rediger gruppe',
  Permissions: 'Tillatelser',
  'Delete Group': 'Slett gruppe',
  'New Group': 'Ny gruppe',
  'Create Group': 'Lag gruppe',
  Group: 'Gruppe',
  'Create Security Group': 'Opprett sikkerhetsgruppe',
  'Group Name': 'Gruppenavn',
  'Select User': 'Velg Bruker',
  'Bind to Group': 'Bind til gruppe',
  Create: 'Skape',
  Close: 'Lukk',
  'Remove user from group': 'Fjern brukeren fra gruppen',
  UserManagement: 'Brukeradministrasjon',
  Update: 'Oppdater',
  Read: 'Lese',
  'General Permissions': 'Generelle tillatelser',
  Column: 'Kolonne',
  'Eform Permissions': 'Eform-tillatelser',
  'Add Eform to Group': 'Legg til Eform i gruppen',
  Forbidden: 'Forbudt',
  'Add user to group': 'Legg bruker til gruppe',
  'Update columns': 'Oppdater kolonner',
  'Download XML': 'Last ned XML',
  'Upload ZIP': 'Last opp ZIP',
  'Pairing read': 'Paring lest',
  'Pairing update': 'Sammenkoblingsoppdatering',
  'Read tags': 'Les tagger',
  'Update tags': 'Oppdater tagger',
  'Get CSV': 'Hent CSV',
  'Get DOCX': 'Skaff deg DOCX',
  'Get PDF': 'Få PDF',
  'Get PPTX': 'Få PPTX',
  'Cases read': 'Saker lest',
  'Case read': 'Sak lest',
  'Case update': 'Saksoppdatering',
  'Case delete': 'Slett sak',
  'Bind Eform': 'Bind Eform',
  'Read Jasper report': 'Les Jasper-rapporten',
  'Update Jasper report': 'Oppdater Jasper-rapporten',
  Display: 'Vise',
  Enabled: 'Aktivert',
  Disabled: 'Funksjonshemmet',
  'Plugins Settings': 'Plugins',
  PluginsSettings: 'Plugins',
  'Edit Settings': 'Endre innstillinger',
  'Connection String': 'Tilkoblingsstreng',
  Version: 'Versjon',
  'Header Picture': 'Overskriftsbilde',
  Preview: 'Forhåndsvisning',
  Commentary: 'Kommentar',
  Text: 'Tekst',
  'Selected Value': 'Valgt verdi',
  'PDF Link': 'PDF-lenke',
  Information: 'Informasjon',
  Duration: 'Varighet',
  Audio: 'Lyd',
  'Are you sure you want to install plugin': 'Er du sikker på at du vil installere plugin',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Dette kan ta opptil 10 minutter og omskriv gjeldende plugin-versjon hvis den allerede finnes i systemet',
  Installation: 'Installasjon',
  Install: 'Installere',
  Marketplace: 'Markedsplass',
  'Plugins marketplace': 'Markedsplass for plugins',
  'Plugins Management': 'Programtilleggsadministrasjon',
  'Warning: you will be logged out when status is changed': 'Advarsel: du vil bli logget ut når status endres',
  'Select tags for site': 'Velg tagger for nettstedet',
  'Add new': 'Legg til ny',
  'Email Recipients': 'E-postmottakere',
  'Manage tags': 'Administrer tagger',
  'Add Recipients': 'Legg til mottakere',
  'Edit email recipient': 'Rediger e-postmottaker',
  'Delete email recipient': 'Slett e-postmottaker',
  'No email recipients found': 'Finner ingen e-postmottakere',
  'New Email recipients': 'Nye e-postmottakere',
  'Email addresses': 'E-post adresse',
  'Edit tag name': 'Rediger tagnavn',
  'Tags management': 'Tags administrasjon',
  'Email/name text area should have at least one email/name pair': 'Tekstområdet for e-post/navn bør ha minst ett e-post/navn-par',
  'SendGrid Key': 'SendGrid-nøkkel',
  'SendGrid Information': 'SendGrid-informasjon',
  'Saved locally': 'Lagret lokalt',
  'Ready for server': 'Klar for server',
  'Received by server': 'Mottatt av server',
  'Retrieved by device': 'Hentet av enhet',
  Completed: 'Fullført',
  'Case posts': 'Saksinnlegg',
  'No posts found': 'Ingen innlegg funnet',
  'eForm conducted by': 'eForm utført av',
  'New post': 'Nytt innlegg',
  'Created by': 'Laget av',
  To: 'Til',
  Subject: 'Emne',
  'Email text': 'e-post tekst',
  'Link to case': 'Link til sak',
  'Attach PDF': 'Legg ved PDF',
  'Post created by': 'Innlegg laget av',
  'Post sent to': 'Post sendt til',
  Location: 'plassering',
  'InSight Version': 'InSight-versjon',
  'eForm Version': 'eForm versjon',
  'Sync delay': 'Synkroniseringsforsinkelse',
  'Activate sync delay': 'Aktiver synkroniseringsforsinkelse',
  'Sync dialog': 'Synkroniseringsdialog',
  'Activate sync dialog': 'Aktiver synkroniseringsdialog',
  Push: 'Trykk',
  'Activate push': 'Aktiver push',
  'Move unit': 'Flytt enheten',
  'Create unit': 'Lag enhet',
  'Select location': 'Velg plassering',
  Move: 'Bevege seg',
  'Current tags': 'Gjeldende tagger',
  'Edit selectable element': 'Rediger valgbart element',
  'Edit selectable list': 'Rediger valgbar liste',
  Folders: 'Mapper',
  Description: 'Beskrivelse',
  'Create folder': 'Lag mappe',
  'Storage information': 'Lagringsinformasjon',
  'Swift enabled': 'Swift aktivert',
  'User name': 'Brukernavn',
  'Swift endpoint': 'Swift endepunkt',
  'Keystone endpoint': 'Keystone-endepunkt',
  'S3 enabled': 'S3 aktivert',
  'S3 accessKey': 'S3 tilgangsnøkkel',
  'S3 secretAccessKey': 'S3 hemmelig tilgangsnøkkel',
  'S3 endpoint': 'S3 endepunkt',
  'S3 bucket name': 'Navn på S3-bøtte',
  'File location picture': 'Filplasseringsbilde',
  'File location PDF': 'Filplassering PDF',
  'File location Jasper report files': 'Filplassering Jasper rapportfiler',
  'Http server address': 'Http-serveradresse',
  'SDK information': 'SDK-informasjon',
  'Customer no': 'Kundenr',
  Management: 'Ledelse',
  'Update security group': 'Oppdater sikkerhetsgruppe',
  'eForms permissions': 'eForms-tillatelser',
  'Menu editor': 'Menyredigering',
  'Edit menu entry': 'Rediger menyoppføring',
  Link: 'Link',
  'Are you sure you want to delete menu entry': 'Er du sikker på at du vil slette menyoppføringen',
  'New dropdown': 'Ny rullegardin',
  Dropdown: 'Fall ned',
  'Security groups': 'Sikkerhetsgrupper',
  Custom: 'Tilpasset',
  Add: 'Legg til',
  'Generate report': 'Generer {{value}}-rapport',
  'Select date range': 'Velg datoperiode',
  'Edit headers': 'Rediger overskrifter',
  'Text (TextSingleLine)': 'Tekst enkelt linje',
  Generate: 'Skap rapport',
  CheckBox: 'Avmerkingsboks',
  SingleSelect: 'Fall ned',
  Comment: 'Tekst',
  Number: 'Numerisk',
  Picture: 'Bilde',
  None: 'Infoboks',
  Date: 'Dato',
  SaveButton: 'Lagre',
  ShowPDF: 'PDF',
  FieldGroup: 'Gruppe',
  NumberStepper: 'Stepper',
  Signature: 'Signatur',
  Timer: 'StartStoppTid',
  EntitySearch: 'Dropdown-søk (mine lister)',
  EntitySelect: 'Dropdown (mine lister)',
  MultiSelect: 'Flervalg',
  'Save eForm.': 'Lagre eForm',
  'Server date': 'Server dato',
  'eForm Pairing for': 'eForm Paring for',
  'Select Site': 'Velg nettsted',
  Select: 'Plukke ut',
  Employee: 'Ansatt',
  Site: 'Nettstedet',
  'Selected language': 'Valgt språk',
  'New folder': 'Ny mappe',
  Back: 'Tilbake',
  Danish: 'dansk',
  English: 'Engelsk',
  German: 'tysk',
  Spanish: 'spansk',
  French: 'fransk',
  Italian: 'italiensk',
  Polish: 'Pusse',
  Portuguese: 'portugisisk',
  'Brazilian Portuguese': 'brasiliansk portugisisk',
  Ukrainian: 'ukrainsk',
  Swedish: 'svensk',
  Norwegian: 'norsk',
  Dutch: 'nederlandsk',
  Finish: 'Bli ferdig',
  'Done at': 'Ferdig kl',
  'Select date': 'Velg dato',
  Value: 'Verdi',
  'Please fill out this field': 'Vennligst fyll ut dette feltet',
  'Submitted date': 'Innsendt dato',
  Languages: 'Språk',
};
