export const skSK = {
  Settings: 'nastavenie',
  Save: 'Uložiť',
  'Login Page': 'Prihlasovacia stránka',
  Reset: 'Resetovať',
  'Main Text': 'Hlavný text',
  'Secondary Text': 'Sekundárny text',
  Image: 'Obrázok',
  'Recommended parameters': 'Odporúčané parametre',
  Resolution: 'Rozhodnutie',
  Proportions: 'Proporcie',
  'Site header': 'Hlavička stránky',
  Logo: 'Logo',
  'SMTP Information': 'Informácie o SMTP',
  Host: 'Hostiteľ',
  Port: 'Port',
  Login: 'Prihlásiť sa',
  Password: 'heslo',
  'Forgot password': 'Zabudol si heslo',
  'Restore password': 'Obnoviť heslo',
  'Reset admin password': 'Obnoviť heslo správcu',
  'Back to login': 'Späť na prihlásenie',
  'Reset password': 'Obnoviť heslo',
  Approved: 'Schválené',
  Review: 'Preskúmanie',
  'Not approved': 'Nebol schválený',
  'Updating cases, please wait': 'Aktualizujú sa prípady, čakajte',
  Search: 'Vyhľadávanie',
  done_at: 'Elektronický formulár skončil o',
  created_at: 'Údaje dostupné na',
  worker_name: 'eFormulár vyhotovil',
  'Edit Case': 'Upraviť prípad',
  'Delete Case': 'Odstrániť prípad',
  Download: 'Stiahnuť ▼',
  'Are you sure you want to delete this case': 'Naozaj chcete odstrániť tento prípad?',
  'Accessible at': 'Prístupné na',
  'Finished at': 'Dokončené o',
  'Done by': 'Vykonáva',
  OK: 'OK',
  Cancel: 'Zrušiť',
  'Edit Searchable item': 'Upraviť vyhľadávateľnú položku',
  'Delete Searchable item': 'Odstrániť položku s možnosťou vyhľadávania',
  'Edit searchable element': 'Upravte vyhľadávateľný prvok',
  Name: 'názov',
  Ok: 'Dobre',
  'Import options (max 100 entries)': 'Možnosti importu (max. 100 záznamov)',
  Import: 'Importovať',
  'My eForms': 'Moje elektronické formuláre',
  'Create eForm': 'Vytvorte elektronický formulár',
  'New eForm': 'Nový elektronický formulár',
  'New eForm from XML': 'Nový elektronický formulár z XML',
  Label: 'Označenie',
  Tags: 'Tagy',
  CreatedAt: 'Vytvorené o',
  'eForm Name': 'Názov elektronického formulára',
  Pairing: 'Párovanie',
  Actions: 'Akcie',
  'Edit tags': 'Upravte značky',
  'Click to edit eForm pairing with user(s)': 'Kliknutím upravíte párovanie elektronického formulára s používateľmi',
  'Edit pairing': 'Upraviť párovanie',
  'Click to pair eForm with user(s)': 'Kliknutím spárujete elektronický formulár s používateľmi',
  'Pair eForm': 'Spárovať elektronický formulár',
  'Show cases': 'Zobraziť prípady',
  'Edit columns': 'Upravte stĺpce',
  'Download CSV': 'Stiahnite si CSV',
  'Delete eForm': 'Odstrániť eFormulár',
  'Upload ZIP archive': 'Nahrajte archív ZIP',
  'Upload template ZIP': 'Nahrajte šablónu ZIP',
  'Uploading progress': 'Priebeh nahrávania',
  'Save eForm': 'Uložiť elektronický formulár',
  'Device user': 'Používateľ zariadenia',
  'Check to pair': 'Začiarknutím sa spárujete',
  'Save pairing': 'Uložiť párovanie',
  'Select columns for displaying in Cases': 'Vyberte stĺpce, ktoré sa majú zobraziť v prípadoch',
  Field: 'Lúka',
  'Are you sure you want to delete': 'Naozaj chcete odstrániť?',
  'Creation Date': 'Dátum vytvorenia',
  'Select tags for template': 'Vyberte značky pre šablónu',
  'Template tags': 'Štítky šablón',
  'New tag': 'Nová značka',
  'Tag list': 'Zoznam značiek',
  'Add field': 'Pridať pole',
  'Add sub eForm': 'Pridať podriadený elektronický formulár',
  'Edit sub eForm': 'Upraviť čiastkový elektronický formulár',
  'New sub eForm': 'Nový podriadený elektronický formulár',
  'New field': 'Nové pole',
  'Field is not complete': 'Pole nie je úplné',
  'Add new nested field': 'Pridať pole do skupiny',
  'Copy field': 'Kopírovať pole',
  'Edit field': 'Upraviť pole',
  'Delete field': 'Odstrániť pole',
  'Expand field': 'Zobraziť polia v skupine',
  'Collapse field': 'Skryť polia v skupine',
  Mandatory: 'Povinné',
  Type: 'Typ',
  'Are you sure you want to delete field': 'Naozaj chcete odstrániť pole?',
  'eForm is not complete': 'Elektronický formulár nie je úplný',
  'Edit eForm': 'Upraviť elektronický formulár',
  'New nested field': 'Nové vnorené pole',
  Delete: 'Odstrániť',
  'XML from eForm Builder': 'XML z nástroja eForm Builder',
  'Paste XML here': 'Sem vložte XML',
  'Creation date': 'Dátum vytvorenia',
  'Searchable lists': 'Prehľadateľné zoznamy',
  'Searchable list': 'Prehľadateľný zoznam',
  'Create new searchable list': 'Vytvorte nový zoznam s možnosťou vyhľadávania',
  'Edit site': 'Upraviť stránku',
  'Selectable lists': 'Zoznamy s možnosťou výberu',
  'Selectable list': 'Voliteľný zoznam',
  'Create new selectable list': 'Vytvorte nový voliteľný zoznam',
  'Terms of Service': 'Podmienky služby',
  'Privacy Policy': 'Zásady ochrany osobných údajov',
  'Data Protection Terms (GDPR)': 'Podmienky ochrany údajov (GDPR)',
  'Save password': 'Uložiť heslo',
  'Edit Device User': 'Upraviť používateľa zariadenia',
  'First name': 'Krstné meno',
  'Last name': 'Priezvisko',
  'Device Users': 'Používatelia zariadenia',
  'Create Device User': 'Vytvoriť používateľa zariadenia',
  'New Device User': 'Nový používateľ zariadenia',
  'Site ID': 'ID lokality',
  'Device ID': 'ID zariadenia',
  Customer: 'Zákazník',
  'Customer no & OTP': 'Číslo zákazníka a jednorazové heslo',
  'New OTP': 'Nové OTP',
  'Delete Device User': 'Odstrániť používateľa zariadenia',
  WARNING: 'POZOR',
  'When pressing OK, you will deactivate the current device paired with this location': 'Stlačením OK deaktivujete aktuálne zariadenie spárované s týmto umiestnením',
  'If there is data on the current device, this data will be lost after pressing OK': 'Ak sú na aktuálnom zariadení dáta, tieto dáta sa po stlačení OK stratia',
  Edit: 'Upraviť',
  Sites: 'Stránky',
  'Updated at': 'Aktualizované o',
  'Are you sure you want to delete the site? The action can not be undone': 'Naozaj chcete odstrániť lokalitu? Akciu nie je možné vrátiť späť',
  Previous: 'Predchádzajúce',
  Next: 'Ďalšie',
  Units: 'Jednotky',
  'Get OTP code': 'Získajte kód OTP',
  'Activate OTP code for': 'Aktivujte kód OTP pre',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Stlačením tlačidla OK deaktivujete aktuálnu jednotku priradenú k tomuto umiestneniu',
  'You will se the new OTP code once you click OK': 'Po kliknutí na tlačidlo OK sa zobrazí nový kód OTP',
  Workers: 'Robotníci',
  'Create Worker': 'Vytvoriť pracovníka',
  'Edit Worker': 'Edit Worker',
  'Delete Worker': 'Odstrániť pracovníka',
  'Are you sure you want to delete the worker': 'Naozaj chcete odstrániť pracovníka?',
  'Select Worker': 'Vyberte položku Pracovník',
  'Save Worker': 'Zachráňte pracovníka',
  Advanced: 'Admin',
  'Application Settings': 'Nastavenia aplikácie',
  'User management': 'Správa užívateľov',
  'Change password': 'Zmeniť heslo',
  Logout: 'Odhlásiť sa',
  'Profile settings': 'Nastavenia profilu',
  Timezone: 'Časové pásmo',
  Language: 'Jazyk',
  'Force users to use 2-f-auth': 'Vynútiť používateľom používanie 2-f-auth',
  'Old password': 'Staré heslo',
  'New password': 'Nové heslo (min. 6 znakov)',
  'New password confirmation': 'Potvrdenie nového hesla (min. 6 znakov)',
  'Confirm new password': 'Potvrďte nové heslo (min. 6 znakov)',
  'Enter new password': 'Zadajte nové heslo (min. 6 znakov)',
  'Enter old password': 'Zadajte staré heslo',
  'New User': 'Nový užívateľ',
  'Edit User': 'Upraviť používateľa',
  'Left empty if want to keep old': 'Ak chcete zostať starý, nechajte ho prázdne',
  Username: 'Používateľské meno',
  Email: 'E-mail',
  Status: 'Postavenie',
  'change will logout you from the application': 'zmena vás odhlási z aplikácie',
  'Remove google authenticator secret key': 'Odstráňte tajný kľúč google authenticator',
  'Remove PSK': 'Odstráňte PSK',
  'Full Name': 'Celé meno',
  'Delete user': 'Odstrániť používateľa',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Otvorte Google Authenticator a pridajte aplikáciu Eform naskenovaním QR kódu vpravo',
  'Enter the 6 digit code that Google Authenticator generates': 'Zadajte 6-miestny kód, ktorý vygeneruje aplikácia Google Authenticator',
  General: 'generál',
  Find: 'Nájsť',
  'Nothing found': 'Nič nebolo nájdené',
  'Type in search box to see results': 'Ak chcete zobraziť výsledky, zadajte do vyhľadávacieho poľa',
  'Select tags for filter': 'Vyberte značky pre filter',
  'All selected': 'Všetky vybraté',
  'Showing to of records': 'Zobrazuje sa {{currentPageAmount}} až {{currentPage}} z {{total}} záznamov',
  'Google Authenticator description': '<p>Tajný kľúč medzi aplikáciou <strong>Eform</strong> a <strong>aplikáciou Google Authenticator</strong> . Udržujte to v bezpečí a nikomu to nehovorte.</p><p> Tento kľúč by ste mali odstrániť, ak je <strong>Google Authenticator</strong> preinštalovaný alebo bol odstránený kľúč</p>',
  'New OTP for': 'Nové jednorazové heslo pre',
  Loading: 'Načítava',
  Pending: 'Čaká na spracovanie',
  Upload: 'Nahrať',
  Role: 'Role',
  'New list': 'Nový zoznam',
  'Name is required': 'Meno je povinné',
  'Edit searchable list': 'Upravte vyhľadávateľný zoznam',
  'eForm Pairing': 'Párovanie elektronického formulára',
  'Tag for removal': 'Štítok na odstránenie',
  'New tags': 'Nové značky',
  'Rotate on 90': 'Otočiť o 90',
  'Delete picture': 'Odstrániť obrázok',
  'Open location': 'Otvoriť umiestnenie',
  'File has been uploaded successfully': 'Súbor bol úspešne odovzdaný',
  'Error while uploading file': 'Chyba pri nahrávaní súboru',
  Customers: 'zákazníkov',
  'Do you want to leave this page': 'Chcete opustiť túto stránku?',
  'If you leave before saving your changes will be lost': 'Ak odídete pred uložením, zmeny sa stratia',
  'Save & Leave': 'Uložiť a odísť',
  Cases: 'Prípady',
  Calendar: 'Kalendár',
  'Case management': 'Vedenie prípadov',
  Security: 'Bezpečnosť',
  'Security Groups': 'bezpečnostné skupiny',
  GroupName: 'Názov skupiny',
  UserAmount: 'Množstvo používateľov',
  'Edit Group': 'Upraviť skupinu',
  Permissions: 'Povolenia',
  'Delete Group': 'Odstrániť skupinu',
  'New Group': 'Nová skupina',
  'Create Group': 'Vytvoriť skupinu',
  Group: 'Skupina',
  'Create Security Group': 'Vytvorte bezpečnostnú skupinu',
  'Group Name': 'Názov skupiny',
  'Select User': 'Vyberte položku Používateľ',
  'Bind to Group': 'Zviazať do skupiny',
  Create: 'Vytvorte',
  Close: 'Zavrieť',
  'Remove user from group': 'Odstrániť používateľa zo skupiny',
  UserManagement: 'Správa užívateľov',
  Update: 'Aktualizovať',
  Read: 'Čítať',
  'General Permissions': 'Všeobecné povolenia',
  Column: 'Stĺpec',
  'Eform Permissions': 'Eform povolenia',
  'Add Eform to Group': 'Pridajte Eform do skupiny',
  Forbidden: 'Zakázané',
  'Add user to group': 'Pridať používateľa do skupiny',
  'Update columns': 'Aktualizujte stĺpce',
  'Download XML': 'Stiahnite si XML',
  'Upload ZIP': 'Nahrajte ZIP',
  'Pairing read': 'Čítanie párovania',
  'Pairing update': 'Aktualizácia párovania',
  'Read tags': 'Prečítajte si značky',
  'Update tags': 'Aktualizujte značky',
  'Get CSV': 'Získajte CSV',
  'Get DOCX': 'Získajte DOCX',
  'Get PDF': 'Získajte PDF',
  'Get PPTX': 'Získajte PPTX',
  'Cases read': 'Prípady prečítané',
  'Case read': 'Prípad prečítaný',
  'Case update': 'Aktualizácia prípadu',
  'Case delete': 'Prípad odstrániť',
  'Bind Eform': 'Bind Eform',
  'Read Jasper report': 'Prečítajte si Jasperovu správu',
  'Update Jasper report': 'Aktualizujte Jasperovu správu',
  Display: 'Displej',
  Enabled: 'Povolené',
  Disabled: 'Zakázané',
  'Plugins Settings': 'Pluginy',
  PluginsSettings: 'Pluginy',
  'Edit Settings': 'Upraviť nastavenia',
  'Connection String': 'Pripojovací reťazec',
  Version: 'Verzia',
  'Header Picture': 'Obrázok hlavičky',
  Preview: 'Náhľad',
  Commentary: 'Komentár',
  Text: 'Text',
  'Selected Value': 'Vybraná hodnota',
  'PDF Link': 'Odkaz na PDF',
  Information: 'Informácie',
  Duration: 'Trvanie',
  Audio: 'Zvuk',
  'Are you sure you want to install plugin': 'Naozaj chcete nainštalovať doplnok?',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Môže to trvať až 10 minút a prepísať aktuálnu verziu doplnku, ak už v systéme existuje',
  Installation: 'Inštalácia',
  Install: 'Inštalácia',
  Marketplace: 'Trhovisko',
  'Plugins marketplace': 'Trh s pluginmi',
  'Plugins Management': 'Správa pluginov',
  'Warning: you will be logged out when status is changed': 'Upozornenie: Pri zmene stavu budete odhlásení',
  'Select tags for site': 'Vyberte značky pre web',
  'Add new': 'Pridať nové',
  'Email Recipients': 'Príjemcovia e-mailu',
  'Manage tags': 'Spravovať značky',
  'Add Recipients': 'Pridať príjemcov',
  'Edit email recipient': 'Upravte príjemcu e-mailu',
  'Delete email recipient': 'Odstrániť príjemcu e-mailu',
  'No email recipients found': 'Nenašli sa žiadni príjemcovia e-mailu',
  'New Email recipients': 'Noví príjemcovia e-mailu',
  'Email addresses': 'Emailové adresy',
  'Edit tag name': 'Upravte názov značky',
  'Tags management': 'Správa značiek',
  'Email/name text area should have at least one email/name pair': 'Textová oblasť email/meno by mala obsahovať aspoň jeden pár email/meno',
  'SendGrid Key': 'Kľúč SendGrid',
  'SendGrid Information': 'Informácie SendGrid',
  'Saved locally': 'Uložené lokálne',
  'Ready for server': 'Pripravené na server',
  'Received by server': 'Prijaté serverom',
  'Retrieved by device': 'Načítané zariadením',
  Completed: 'Dokončené',
  'Case posts': 'Prípadové príspevky',
  'No posts found': 'Nenašli sa žiadne príspevky',
  'eForm conducted by': 'eFormulár, ktorý vedie',
  'New post': 'Nový príspevok',
  'Created by': 'Vytvoril',
  To: 'Komu',
  Subject: 'Predmet',
  'Email text': 'text e-mailu',
  'Link to case': 'Odkaz na prípad',
  'Attach PDF': 'Priložiť PDF',
  'Post created by': 'Príspevok vytvoril',
  'Post sent to': 'Príspevok bol odoslaný na adresu',
  Location: 'Poloha',
  'InSight Version': 'Verzia InSight',
  'eForm Version': 'Verzia elektronického formulára',
  'Sync delay': 'Oneskorenie synchronizácie',
  'Activate sync delay': 'Aktivujte oneskorenie synchronizácie',
  'Sync dialog': 'Dialógové okno synchronizácie',
  'Activate sync dialog': 'Aktivovať dialógové okno synchronizácie',
  Push: 'TAM',
  'Activate push': 'Aktivovať push',
  'Move unit': 'Presuňte jednotku',
  'Create unit': 'Vytvorte jednotku',
  'Select location': 'Vyberte umiestnenie',
  Move: 'Pohybujte sa',
  'Current tags': 'Aktuálne značky',
  'Edit selectable element': 'Upraviť voliteľný prvok',
  'Edit selectable list': 'Upraviť voliteľný zoznam',
  Folders: 'Priečinky',
  Description: 'Popis',
  'Create folder': 'Vytvoriť priečinok',
  'Storage information': 'Informácie o úložisku',
  'Swift enabled': 'Swift je povolený',
  'User name': 'Používateľské meno',
  'Swift endpoint': 'Rýchly koncový bod',
  'Keystone endpoint': 'Keystone koncový bod',
  'S3 enabled': 'S3 povolené',
  'S3 accessKey': 'Prístupový kľúč S3',
  'S3 secretAccessKey': 'Tajný prístupový kľúč S3',
  'S3 endpoint': 'Koncový bod S3',
  'S3 bucket name': 'Názov vedra S3',
  'File location picture': 'Obrázok umiestnenia súboru',
  'File location PDF': 'Umiestnenie súboru PDF',
  'File location Jasper report files': 'Umiestnenie súboru Súbory správ Jasper',
  'Http server address': 'Adresa HTTP servera',
  'SDK information': 'Informácie o súprave SDK',
  'Customer no': 'Zákaznícke číslo',
  Management: 'Zvládanie',
  'Update security group': 'Aktualizujte bezpečnostnú skupinu',
  'eForms permissions': 'Povolenia pre elektronické formuláre',
  'Menu editor': 'Editor menu',
  'Edit menu entry': 'Upraviť položku ponuky',
  Link: 'Odkaz',
  'Are you sure you want to delete menu entry': 'Naozaj chcete odstrániť položku ponuky?',
  'New dropdown': 'Nový rozbaľovací zoznam',
  Dropdown: 'Rozbaľovacia ponuka',
  'Security groups': 'Bezpečnostné skupiny',
  Custom: 'Vlastné',
  Add: 'Pridať',
  'Generate report': 'Vygenerujte prehľad {{value}}',
  'Select date range': 'Vyberte rozsah dátumov',
  'Edit headers': 'Upraviť hlavičky',
  'Text (TextSingleLine)': 'Text v jednom riadku',
  Generate: 'Vytvorte vzťah',
  CheckBox: 'začiarkavacie políčko',
  SingleSelect: 'Rozbaľovacia ponuka',
  Comment: 'Text',
  Number: 'Numerický',
  Picture: 'Obrázok',
  None: 'Infobox',
  Date: 'Dátum',
  SaveButton: 'Uložiť',
  ShowPDF: 'PDF',
  FieldGroup: 'Skupina',
  NumberStepper: 'Stepper',
  Signature: 'Podpis',
  Timer: 'StartStopTime',
  EntitySearch: 'Rozbaľovacie vyhľadávanie (moje zoznamy)',
  EntitySelect: 'Rozbaľovacia ponuka (moje zoznamy)',
  MultiSelect: 'Viacnásobný výber',
  'Save eForm.': 'Uložiť elektronický formulár',
  'Server date': 'Dátum servera',
  'eForm Pairing for': 'Párovanie elektronického formulára pre',
  'Select Site': 'Vyberte položku Lokalita',
  Select: 'Vyberte',
  Employee: 'zamestnanec',
  Site: 'stránky',
  'Selected language': 'Vybraný jazyk',
  'New folder': 'Nový priečinok',
  Back: 'späť',
  Danish: 'dánčina',
  English: 'Angličtina',
  German: 'nemecký',
  Spanish: 'španielčina',
  French: 'francúzsky',
  Italian: 'taliansky',
  Polish: 'poľský',
  Portuguese: 'portugalčina',
  'Brazilian Portuguese': 'brazílska portugalčina',
  Ukrainian: 'Ukrajinčina',
  Swedish: 'švédsky',
  Norwegian: 'nórsky',
  Dutch: 'holandský',
  Finish: 'Skončiť',
  'Done at': 'Hotovo o',
  'Select date': 'Vyberte dátum',
  Value: 'Hodnota',
  'Please fill out this field': 'Vyplňte toto pole',
  'Submitted date': 'Dátum odoslania',
  Languages: 'Jazyky',
};
